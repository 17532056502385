{
  "assets/blog/img/aquecimento-direto-indireto-mobile.png": "9zvTc1hnSk2r-b3AtvZX7ufJ0JV1FSGMHJNICrNcpPU",
  "assets/blog/img/aquecimento-direto-indireto-mobile.webp": "Kn8ue5DDCw11T9mo7fktubDJhxnjR7B8KeA-v7_jdwE",
  "assets/blog/img/aquecimento-direto-indireto.png": "mW4_ad9LiAtzlRTKpj6uX08wQ7gjDjwoYNN2zKYS5wU",
  "assets/blog/img/aquecimento-direto-indireto.webp": "l5rgtlRMs2R2DmCBm6lqMYphn-Q5h2fAN4X24YVsmdo",
  "assets/blog/img/chuveiro-a-gas-natural-mobile.png": "YdQByWW-jWwfHlrJbfps0bNs0n5_YZdOj3m0CIWBB-k",
  "assets/blog/img/chuveiro-a-gas-natural-mobile.webp": "Ayszsb7PpuEuIHQGEmlDstpWg9Z-Po-kYXivhWXLzpE",
  "assets/blog/img/chuveiro-a-gas-natural.png": "Wn_51nuYTr8r5MhwRLWD6sCxmhbbHU_BDeAIxykQpWw",
  "assets/blog/img/chuveiro-a-gas-natural.webp": "qAynk7bNWes461Q1_qAxM0vJd1nwRgca3fXFYpziZw4",
  "assets/blog/img/clube-com-piscina-aquecida-coberta-mobile.png": "_lxxue8ARNDpWjR7jtPnkegQndGeDQ_1-Sja-GRHvz0",
  "assets/blog/img/clube-com-piscina-aquecida-coberta-mobile.webp": "yH4hPI3pw2WDb-jvKpwXktSvyh4aTjZzSF2AoDrC0Jc",
  "assets/blog/img/clube-com-piscina-aquecida-coberta.png": "WrqnDIvcLBeKRIUzr48q7WloqUW8XKOJNHyh7ddMLUg",
  "assets/blog/img/clube-com-piscina-aquecida-coberta.webp": "tU5P3oD6iUY7Rn9p3dPrZxc70I1qDhMnMLh4KIrCibw",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente-mobile.png": "T4aOBHIy4ON9Wes-dnzKcATq1LLAX64tklMTpBRpUZQ",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente-mobile.webp": "ojM35AHaab_khI0DfccS6Qey3vLK_YRNXwUxKjrmNDc",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente.png": "3RDyn3KpSigiVpj6cTbaOIa-UInLttMvqhlD4pvYi_g",
  "assets/blog/img/crianca-mergulhando-em-piscina-de-agua-quente.webp": "EPIsijII8VKz95mTqTnTCWMkrepJNyBt-7ONLMADtE4",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura-mobile.png": "UjJGUmw_goZ3xzx1ZEcN1hvLiGENjmnbcQFlv-jIPgo",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura-mobile.webp": "_PgD7yDWIPfDdsT3EmyDyNbeMnju5KJK89MD2gzQOZY",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura.png": "8LVp9hDTNXBzrMwBaQD9C5VYuWXHuB9b7jwr-vp3rL0",
  "assets/blog/img/piscina-aquecida-a-gas-com-cobertura.webp": "CLPxptptekaauCQycNuVnHgCh9uKJG1j_WQABJJO5o4",
  "assets/blog/img/piscina-aquecida-a-gas-natural-mobile.png": "vBC2LsWspncMFzEbP8PacbK3Va2ewcV1b_oXj0S-G0U",
  "assets/blog/img/piscina-aquecida-a-gas-natural-mobile.webp": "V8NRBaJs3WyZ5kiEW_-PDhLprltVo4nwKzKW2uREsrA",
  "assets/blog/img/piscina-aquecida-a-gas-natural.png": "br2DXe6jKZp8Di7gbrDLqzlTeL5JRN0LeSoCjLB5KtY",
  "assets/blog/img/piscina-aquecida-a-gas-natural.webp": "VhmYO7WDkGh096ShurMvc7qxeD4iLgx5gphSB_JJGOU",
  "assets/blog/img/piscina-de-agua-quente-em-condominio-mobile.png": "DlmLCnXm6CeAYzrVeWTa2_7MIaSiX2JhEQ_4LGyxUnU",
  "assets/blog/img/piscina-de-agua-quente-em-condominio-mobile.webp": "qrNpqDRlHEqyjpwoC4PT26IluzLVVAbRQC2UQkugaGg",
  "assets/blog/img/piscina-de-agua-quente-em-condominio.png": "U1-DjzTRQXbrOUTmxcPtMqRSmwwkFap-CufsF6MGwGA",
  "assets/blog/img/piscina-de-agua-quente-em-condominio.webp": "UipUQ0Wym7ABA0SUtYoJ3-kg7AJAbUFWKTxuKUXE0YQ",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios-mobile.png": "eK4gFLwlb7PFx07AEQF3so7E7p4jWOJ9MwYdJSZ5bKI",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios-mobile.webp": "63-DeX7wHVgEmTWaqAVNsb_uHH3l1w3VPG88jVvBmwQ",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios.png": "fW5nKhAlnd6_LjCrJYrn1x5s8wnLQr8LTbDHbuZ0OHE",
  "assets/buildings-content/img/cenário-com-piscina-aquecida-em-condomínio-de-prédios.webp": "MC37d52KIAautX5TOYkK1T_bHKG10ucG8qEBfR6J7Tw",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural-mobile.png": "dRcFseh7ISKz0QotXk7aY1F_FAunU7nF9NuwZ2hO8EE",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural-mobile.webp": "dRcFseh7ISKz0QotXk7aY1F_FAunU7nF9NuwZ2hO8EE",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural.png": "F5tijmDBzAJJv8WE37OWxj1RYLEk3fg21ydRQCy8Dj0",
  "assets/buildings-content/img/equipamento-gerador-de-energia-a-gás-natural.webp": "F5tijmDBzAJJv8WE37OWxj1RYLEk3fg21ydRQCy8Dj0",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás-mobile.png": "lkC7SRtkoaHWddmu8Jo0gN-SozIZL-s_UEQUd69I34g",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás-mobile.webp": "fucGlPn2xPil5RSCX21L8Q3TnfhAjGiwg6MxclQAgyI",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás.png": "kA6lMzT1iTngkNSpOD1Bgorx3foDYnxa_s9WgIfLeAQ",
  "assets/buildings-content/img/família-feliz-reunida-em-volta-de-churrasqueira-a-gás.webp": "XcR07eioZX6ze5j0j2C301LmGESz1_OPrPeNMLIrxnk",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural-mobile.png": "IqyOXV-71TWQ0wJjjaBcGi9uP7a295xmrGbMtpjFyN4",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural-mobile.webp": "taGPl7LlYhJcN43RaJ2wHvjemcY3QlT1cS5_m0bpTsw",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural.png": "vmmaVopVv32V5CXRlkrFMFywPiodT_S30dcroYrf-Ko",
  "assets/buildings-content/img/lareira-com-aquecimento-a-gás-natural.webp": "CRx5xtjQbEWQCz4at22HmRGeB7F4wfZ-yHYKLXUhuxY",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado-mobile.png": "_yvVLUHiNtLZZCudYBl8DvP5TtX2iowf7HbebByUNso",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado-mobile.webp": "_yvVLUHiNtLZZCudYBl8DvP5TtX2iowf7HbebByUNso",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado.png": "RNfk-OMaxms_pCwygXO1YVKyiUrKXh0FmZ9tyqAE2UA",
  "assets/commerce-content/img/chefe-profissional-cozinhando-em-fogao-gas-encanado.webp": "RNfk-OMaxms_pCwygXO1YVKyiUrKXh0FmZ9tyqAE2UA",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes-mobile.png": "RH-rHUR3mtVkh-mdHiNDp30zNxE8YzLJ_3f_GR7SYKY",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes-mobile.webp": "uPHR1cw_y-5FU_Z7X25uArv5aMAfDwcqEIX1tLmqFgU",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes.png": "aiY05k1LNHWigTNy4PLU_qOl_F3GwEKaOYk7grIW0cI",
  "assets/commerce-content/img/cliente-albert-einstein-perdizes.webp": "pvTG4mcDjk-rXWLRxrSd_Yhv7-bot2BUyLaOeO3-e3o",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre-mobile.png": "-I62C4LAQA58wlHZAsF8gUgbg4oAbFjEicIoCWHCmWU",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre-mobile.webp": "ArJ2_bKSM4sFfkw9x77XVn-pLe_GCUtnNh9Zw8HGUX8",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre.png": "yzQfGrcp_xVPCYqN98lrnHjtSqI-uvFu067nO1vcnkc",
  "assets/commerce-content/img/cliente-coco-bambu-santo-andre.webp": "N9W6Ye7xcuhQqedf3weWCWzAyp1b-5dGvyel6bLtuwk",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima-mobile.png": "nVJZzvGgBJk1PirXJvtBBhA3PtLYjC3bpSK7-XADCXI",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima-mobile.webp": "REEHSnhKkFTNsTKkGVPFIW4bT4sT82HDy5g-prtOZeM",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima.png": "os1onn24TdZs0-sM9_Hmzt9wFhNvjQPadazRn3X3KQE",
  "assets/commerce-content/img/cliente-dengo-chocolates-faria-lima.webp": "vd1Wc9ivThJz-GLjO-_6sHOtXFFof0a-4jFkJClRejY",
  "assets/commerce-content/img/cliente-fleury-mobile.png": "lN9FuXvWeHLgkUH-oxKr3wTi3GCXTWVdo0iCM9SBNOc",
  "assets/commerce-content/img/cliente-fleury-mobile.webp": "7FdySQjJ7aojAcUbmAnTr1hIpSQldnYiY4J2odUbHm4",
  "assets/commerce-content/img/cliente-fleury.png": "x4Sgm7YaAqToPkcs3GVKFvURYQ5pwlu7Lod_G0qhiTs",
  "assets/commerce-content/img/cliente-fleury.webp": "KtbW7rXOU2LbLkLbyAHTWqp4QlIixXhJfFZtBABSQAU",
  "assets/commerce-content/img/gas-encanado-piscina-mobile.png": "MheP25Ndb44qG_ZQk3olV4cyv7WSOJ2ft84BjxMIo8E",
  "assets/commerce-content/img/gas-encanado-piscina-mobile.webp": "MheP25Ndb44qG_ZQk3olV4cyv7WSOJ2ft84BjxMIo8E",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio-mobile.png": "7XeDuW9IBfevnFi7zrrpcF8A4-IU13cPAEfPdM_2kik",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio-mobile.webp": "7XeDuW9IBfevnFi7zrrpcF8A4-IU13cPAEfPdM_2kik",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio.png": "A5GDwchcl5Y3oKNtMz6O37fbqwhRqlGyuRDf_sWvG_0",
  "assets/commerce-content/img/mulher-empreendedora-tranquila-com-a-instalação-do-gás-natural-em-seu-negócio.webp": "A5GDwchcl5Y3oKNtMz6O37fbqwhRqlGyuRDf_sWvG_0",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado-mobile.png": "1r2WzSEwH4cqHCq-CIqtgnp8nJfTyReZ_w-DLV7sbMU",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado-mobile.webp": "1r2WzSEwH4cqHCq-CIqtgnp8nJfTyReZ_w-DLV7sbMU",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado.png": "gjGyTFpFMaTIubf-zI-gSOO7-cpHHPHUDYydI_2836o",
  "assets/commerce-content/img/pessoa-manuseando-fritadeiras-a-gas-encanado.webp": "gjGyTFpFMaTIubf-zI-gSOO7-cpHHPHUDYydI_2836o",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas-desktop.png": "wKpB-XVhjZsg90KUBxkrMnLwvnI2veuM-bNLbsJoqRI",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas-desktop.webp": "LWU7TrcbGb-KeXLrL2Uhi3rVzqqk6fQdjbb-S3v65Ao",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas.png": "Auvqb9C0Oub_m5lrenWmBYkTM1ixLForNUUCHcQSIK8",
  "assets/faq/img/atendente-de-relacionamento-sac-quero-comgas.webp": "wKyq0fj2HKnKVI2T-EsVY6SVl6mHNISW9ChyCWgUicw",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1366.png": "dYkIKmPiA9AAwqtanrXGGOM-daC8596ckJtOcSG7uxE",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1366.webp": "X3xPLWo9slushf0HG6CEelS_I1ZLzSorrFRky_Q2k5U",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1920.png": "uOHgKVwCVy2_0DnZPmvxwj77mYHEmOaM18VeNzMQmws",
  "assets/home/img/advantages/piscina-aquecida-a-gás-1920.webp": "8EFdTOYVpF82SGiMBbxr7g71nXEybjueBYP3md6m9q0",
  "assets/home/img/advantages/piscina-aquecida-a-gás.png": "le4j4rVeyy7iIek45PTqynFh8kjL7qq1TvvtmXxkWs8",
  "assets/home/img/advantages/piscina-aquecida-a-gás.webp": "mNFn2xncmDtBFytWIJkYuiwe3kdaPen56oWy0rH4csw",
  "assets/home/img/banners/campaign-banner/botijao-375.png": "wnqcGgAq_i8q4vCFuQg4G1o9yntRRe1Vxt-SKGP0QTk",
  "assets/home/img/banners/campaign-banner/botijao-375.webp": "V9LHozlo7yJqE5Tzn66hbIYrqwqqg2_HbSBl2HjOb6c",
  "assets/home/img/banners/campaign-banner/botijao-768.png": "UKcDXbODfURQKaxT__yaQzKaNcXbPF6qBEoc9d3nWew",
  "assets/home/img/banners/campaign-banner/botijao-768.webp": "2nt6YlvzvGeoWZx5PSp0kz7Uea-8ucKe5jfZDs9owNc",
  "assets/home/img/banners/campaign-banner/botijao-992.png": "wrg6BvFYksHMoe-0tHSMWtOxceDRtDJwHqCntbbXsXg",
  "assets/home/img/banners/campaign-banner/botijao-992.webp": "HZn5TTyoy2NuqT8QAuzjdfUAVWXdaCF-iKJZW3Fc1Qc",
  "assets/home/img/banners/campaign-banner/botijao.png": "MZbXV8UCEQuX6MWvWG64LjM8dnnEBMeXec5LHVc9uDA",
  "assets/home/img/banners/campaign-banner/botijao.webp": "zvhevGXZ_k8J9eW_Oi8pVXyMbB12lF5l8lbr-TybSXU",
  "assets/home/img/segments/commerce/gás-encanado-para-comercio.png": "Um4DEakIpCwnQwFONZD0P3Hiuo1F5ZAPeftSCDSKExY",
  "assets/home/img/segments/commerce/gás-encanado-para-comercio.webp": "iawURFaUS1RHjKlO9odgxO91s4vopOxp97vkZIyfDfo",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.png": "dFM7qQKVqmAPwR-hFxcyCaQ9Er7dbBTUM4nOBLpmuQw",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal-mobile.webp": "CLLPZqIBC-_kmYRA3XDVwd8HgpwR4EsUaDQ8CvArh18",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.png": "bF-9r1jHVZlp8Cr8i071lmHRooNaCZCPMo9PSJfAvz4",
  "assets/home/img/segments/commerce/gas-encanado-piscina-comercio-modal.webp": "uijff3MZWC5bw_2DIFBDIl7jBkYBHGb6VB-PcjhrHz8",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.png": "h1oYkoVvdMJ_GnmFPEGmhoAKB8P8pfHvpYuLX11XKoM",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas-mobile.webp": "Qn-nmliuvG2EKmEdr_hCwEazEqymw1o-qF5pz0tUaGQ",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.png": "lWqF-m6S8M9rufkcE_jEKlYb9nwSnhuvYxopHVUBKQw",
  "assets/home/img/segments/commerce/selecionar-para-seu-comercio-querocomgas.webp": "lWqF-m6S8M9rufkcE_jEKlYb9nwSnhuvYxopHVUBKQw",
  "assets/home/img/segments/industries/gás-natural-para-industrias.png": "fUfgVi1yCbx5qewvDV4iK3Lj5G0rad1XiI1J5pa2PqM",
  "assets/home/img/segments/industries/gás-natural-para-industrias.webp": "VB6AByp_wg5HLIbxotoA5et3TEyQqwZxDzZ1WHyU8QM",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.png": "DH0nFZpH8F2kXObKEvUUOdmKpAhgiyN_lHktTOkMqjA",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas-mobile.webp": "EAoJiqmGT_DyXzjMWWvD0BB4hM0NRiDTfnHoScF94As",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.png": "MvcrD6I79I1mDJAQUcEqMw0-U0O-r_D4cea-hwVaB3U",
  "assets/home/img/segments/industries/selecionar-para-sua-industria-querocomgas.webp": "MvcrD6I79I1mDJAQUcEqMw0-U0O-r_D4cea-hwVaB3U",
  "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.png": "tb3WE6jvXmaJoQks4Mq6YC0x-L8jcK78I7che8xLYo4",
  "assets/home/img/segments/residences/fogão-a-gás-encanado-na-residencia.webp": "PABzfHv_1xzpPRbyrfkfarDmT7h0YXZliGzHBetLyxo",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.png": "ADYGwu54kCfdWE4BVfRWF19onG6sY2N2tIMJuqG5Zn0",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal-mobile.webp": "4my6R-dmR_98MajbDqNYT49vt-oHFYtBcotACtpuTa8",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.png": "9wktZVFCB5PaFaJ7f-97wdOUddm1ZJ0ZoXLb4UzfvZo",
  "assets/home/img/segments/residences/gas-encanado-piscina-casa-modal.webp": "WSufGIbI5BI09pJRTjxqsXY5abO-ziEqcIgUy7JMGcM",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.png": "2fsggWK2KKEfdj_iA68kkAM7M-elfI_BXk8H-7UkUkI",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas-mobile.webp": "zB065rTChQN3CDBbGOq9QUCt_tHSclk5663qu76H2-g",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.png": "F3jdxf2zlRwlRK3u4O_-KPMlsbH25rdtaTKQe371dDU",
  "assets/home/img/segments/residences/selecionar-para-sua-residencia-querocomgas.webp": "F3jdxf2zlRwlRK3u4O_-KPMlsbH25rdtaTKQe371dDU",
  "assets/home/img/segments/vehicles/gnv-veiculos-comgas.png": "reeGzxoxkaHDfykIrn4HE3vOvebmy1PrrkbaedjxdPs",
  "assets/home/img/segments/vehicles/gnv-veiculos-comgas.webp": "qKjAac0xUK5Hex8MNjVG0pv42ZpiU55Oc57oOa3JS08",
  "assets/home/img/svg/about/passo-a-passo-desktop.svg": "xMfyy95ZcXk_J0jQMkOr8cNDzkAv5EPRpIvpTSfSxqc",
  "assets/home/img/svg/about/passo-a-passo-mobile.svg": "u86IvBA3z2VZVmJqYxY2adeVsYAFxjgPUTQMlPjLXTI",
  "assets/home/img/svg/icon-banner-slider-next-btn-disabled.svg": "X8har43vDFbI159HIef0hcYWnSTea-zSrTWUNpdYefk",
  "assets/home/img/svg/icon-banner-slider-next-btn.svg": "-TghaEUDzLIPlIef_L3_gyKFc79ES9NW_gS-C0IxvtM",
  "assets/home/img/svg/icon-banner-slider-prev-btn-disabled.svg": "Iz92EHklhuel0TtAXzH8YjZ8XY5wmzS-I0g0CS3UebQ",
  "assets/home/img/svg/icon-banner-slider-prev-btn.svg": "iK3QGVC65cwFZgA7CTojygDm7bw45ZBJ94vkKY0AQoI",
  "assets/home/img/svg/icon-depoimentos-balao.svg": "W2MpvrQXPWqbDrxNdUvYtTM2kBBSfKRCWCAoCcjbaO0",
  "assets/home/img/svg/icon-faq-link-mais-perguntas.svg": "EbtXzcjIUHxvCcRa4lxEJsLl_qf8RaBEQF5pyE9rct4",
  "assets/home/img/svg/icon-perfil-login-desktop.svg": "OYQDKRaRxdnzRHNbm29_odhY-zeB2Lqx4cbHqaO2JmI",
  "assets/home/img/svg/icon-perfil-login-mobile.svg": "M7ciWfdoh9C8_YAMHdnNVyvMBPlLrtYGEf8UFLgnGR8",
  "assets/home/img/svg/icon-segmentos-seta-cta.svg": "5MRjPVWjDDk-IhVslBRNNIAUJ198yFOy8wwwj8t2dws",
  "assets/home/img/svg/icon-slider-next-button-disabled.svg": "2CdXyh4aCjYGLw0VgrbL5T85PrGT1_cds5VbnpBjOS0",
  "assets/home/img/svg/icon-slider-next-button.svg": "VDGwr8sh13GJg8ji2npBtjpEz_rldmGonlhLK0_QUHI",
  "assets/home/img/svg/icon-slider-prev-button-disabled.svg": "5qd09ySjghOpGoX684bRqnLWb4RipWBHn_wD1RwqwC4",
  "assets/home/img/svg/icon-slider-prev-button.svg": "7LJbJJf_YpqXbCh_GPYBpLhjvN8NyHP0zmRDcqZmFpw",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.png": "KkBKZZE6O-2902_j_XOOcMUkwUW0GVt6S8BpucXsKi4",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-desktop.webp": "cpO9DKmVjO3P25Jo5hhJlAj_hUMu4ivQbPR-zQH5VPM",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.png": "V4eXN3D3mhWDILVLe-j5y0-OxL2uDgvVRz-_QkcMkUM",
  "assets/home/img/testimonials/nilson-morador-de-apartamento-e-cliente-quero-comgas-mobile.webp": "99ohqFucdVYte4XWQnDhrOm3F_VOKeQFJNGTuOJDQVA",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.png": "AWD694WWBFWrqWfbDKrbDj3dYrMZIS3Nag-UoCe9TDU",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-desktop.webp": "JD-n3j-ceYUF3klsHrUYcaDV3GR7jtYcce17t83IZc0",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.png": "mkJfSoKVzsjB3KIICtxZqCsyUMmPxev13z7eS_gWR1w",
  "assets/home/img/testimonials/rafaella-hanna-sindica-de-predio-e-cliente-quero-comgas-mobile.webp": "eeQWuc9FHUrDSAkBHUV5ZZz4a8MTZ9IVsfvHG8NkyTc",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.png": "kzf_MQ7x6PFxk72Zfbnzuxhru-LjePfbqu9JLg-LApk",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-desktop.webp": "UT5BuNO37O0RpPPhhR_HkQE4hkH4Rp4E6U5aqMwzsG4",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.png": "VKLqJzFcec9vHyT7CIIgFlMLyqGrAWBgLE5yxxZ5Bn8",
  "assets/home/img/testimonials/roberto-pinheiro-socio-de-restaurante-e-cliente-quero-comgas-mobile.webp": "IXnJzqIL6SU3rBDfkQXGMrly3s3-VIBJx6GQp8ANpN4",
  "assets/img/comgas-metatag.png": "CGO6vybqvR7FWxPbO154D9Nwhs6291be85Ql9za2Sjw",
  "assets/img/menu-hamburger/atendimento-online-comgas-contraste.png": "q4JCMwY4ToGsyr_y-T7VZXqk5OcF2_ps09phOsZgZ4Q",
  "assets/img/menu-hamburger/atendimento-online-comgas-contraste.webp": "ET8WYj7eWCzRYuOHX3PZtCSp8e3tZEvrIRlJZOLXrAA",
  "assets/img/menu-hamburger/atendimento-online-comgas.png": "ocfEl3NFP-NhjMhbKpXCtQfd8cxN1XK3dCj8gFxQ9Ps",
  "assets/img/menu-hamburger/atendimento-online-comgas.webp": "ocfEl3NFP-NhjMhbKpXCtQfd8cxN1XK3dCj8gFxQ9Ps",
  "assets/img/menu-hamburger/svg/logo-icon.svg": "xfjU6Ae5_nrWGWhEotfu57UvjJ-f5u7ebJh-U12IeQM",
  "assets/img/menu-hamburger/svg/sem-a-visita-da-comgas-na-sua-casa.svg": "4HaqvZ4Hr1AN1gKlw0f11Yh0oWeSEU2CQdmBSqOyvNs",
  "assets/img/svg/cris-mobile.svg": "7-G0dNMq1MEreXpada6-zeAqNEnuA3rgEovtJobJSGQ",
  "assets/img/svg/cris.svg": "T12vl3oVUB8G6_3NhrRHvZt8KeizlzddmUFi-FyEeJY",
  "assets/img/svg/footer-arrow.svg": "DRM1RjqJPlLYDi3xVtLeaqYE5YuemvZ5DRQk24MIPls",
  "assets/img/svg/icon-attention-sign.svg": "LAHzJJApBu-EBB_rDAv_ZHqZDsGhjPL4ChNcyUSUr-8",
  "assets/img/svg/icon-caminhao-frete.svg": "ICFmPe7a7zBet_exRVD7Ga_BOk6PyDgPnwsfdPax2_4",
  "assets/img/svg/icon-carro-direita.svg": "WAXXQpWo2AvsvcJSGIupV8zcQqVaGvWvFA-lU1KSDsY",
  "assets/img/svg/icon-cg-address-validation.svg": "_8J3OF4rJfERPr4R2CBLRMZDobHC_TxOLsw1IgZFnVI",
  "assets/img/svg/icon-cg-alerta.svg": "it6ardQB3jSCyMstQ74wohjE0zIu2gB9RwHD03K03a4",
  "assets/img/svg/icon-cg-atencao-branco.svg": "Kb_JZDqiBPuK9KhhQcYx4GZdt91LD1EwHbkSHlrHkTs",
  "assets/img/svg/icon-cg-check-circle.svg": "5Zfhcq08kQfrWJ8CjFKHJjlSWYj2vdPzR-3-_P0BlcQ",
  "assets/img/svg/icon-cg-facebook-wireframe.svg": "9BfrmL3T8qDg2HCDgGkh8QMeeGJu6IdStyknXur5Yuw",
  "assets/img/svg/icon-cg-facebook.svg": "Vs7_qwhNO6LHl3EPlvYh_baNvn88j6-qCrlx41-jI-Y",
  "assets/img/svg/icon-cg-instagram.svg": "oRz2FCX4TFNyFzHnQh0eeCfzltPxzNK2-ECEx9SDUpY",
  "assets/img/svg/icon-cg-linkedin-wireframe.svg": "mxsCn7zmvtov6_yr82RCi6X_y4UcwvEYwBaz5w0AnL0",
  "assets/img/svg/icon-cg-linkedin.svg": "Ue63ibFnrQ3Z6ChiHWtSP11c0k2SjdpyzoKqK_KFi9c",
  "assets/img/svg/icon-cg-localizacao-gps.svg": "7jPGB5HOG9TG4352yDCxVfWB8F3BnEvlcHiAvyXexTw",
  "assets/img/svg/icon-cg-mao-aperto.svg": "IH93SE03p8tzIe73Sk6Qe24e99X0Uc9g1kR0TTIqfW4",
  "assets/img/svg/icon-cg-perfil.svg": "O_KaIGZGPXQvaj5TlzX8x69BCHZ1p_PUaBw4YK-TCsg",
  "assets/img/svg/icon-cg-twitter-x-wireframe.svg": "P99_COBOxIEMtu9910AhM6AgJVGIZQp2NzYApYFEOEI",
  "assets/img/svg/icon-cg-twitter.svg": "-A6DbjXjDWCh_Kqn-TPoLyhvSdY7CkY9iU4aEHWsjOU",
  "assets/img/svg/icon-cg-whatsapp-wireframe.svg": "VKEAgWDUiz65dbX8qoZkFy0CgvpevaWItYDX7Ek9gu8",
  "assets/img/svg/icon-cg-youtube.svg": "N6VF0m7BRMd5yrPZkfOCwn1xJWJsxYcSmQ2b30G_vXE",
  "assets/img/svg/icon-change-circle-outline.svg": "1_76LOBM3TxxClqDxEZ2dgfMuas5rtgJbleewm7ole4",
  "assets/img/svg/icon-change-segment-feedback.svg": "P7xNGswT8QwY_l--RmKB02ar2sNXwKsH5Ia6vjR9xck",
  "assets/img/svg/icon-dinheiro-salvar-gradiente.svg": "zl5Zwft2qfGhcee7eL-7wPQeyGRIJ0_rpYZB54ql6BM",
  "assets/img/svg/icon-feedback-positive-gradient.svg": "R01D53yr6DnRj_CnB9a45An5i4HYRGDRYBKnXv7SNhI",
  "assets/img/svg/icon-pause.svg": "d0t1BvordcGYzItxff8OCGsMO3HRkLs-1MC-YEi7hOc",
  "assets/img/svg/icon-play.svg": "IMQPIAiXt3V6-i2VCbt0JD16E2VpTXxddLy63MbRl8A",
  "assets/img/svg/icon-seta-direita.svg": "e3yTboWUHb0Ib0COmFlrjx_G4IhCdEp99vXRDifG3HY",
  "assets/img/svg/icon-seta-esquerda.svg": "7jn1wKdyuk6Qno1Y_6Bx7MwhLs0vgKwAhTot9g8BER0",
  "assets/img/svg/icon-stop.svg": "BAKEe06_U3UPF4C5ojAX1MuZOfrCyNUqdFgEJcZlXek",
  "assets/img/svg/loading-animation-white.svg": "_6fxkjzkbFPaZsAbQXoWtWV7IV_KY0Be_Bmx6HPcwxg",
  "assets/img/svg/logo/footer-logo-transparent.svg": "HnWEINed7M6vcSVgnYr8h-9Sj0DvheG8e8sqT6Pf9zQ",
  "assets/img/svg/logo/header-logo-sliced-desktop.svg": "qW_ig-uomOF_G441Z1PVq52U-8C3LGWspCODE38obaM",
  "assets/img/svg/logo/header-logo-sliced-mobile.svg": "4SeFjDCN-TXBLbjOWp_Ic6Iw2znqOtTlVDo2JiS7Xk4",
  "assets/img/svg/logo/header-logo-transparent-desktop.svg": "HnWEINed7M6vcSVgnYr8h-9Sj0DvheG8e8sqT6Pf9zQ",
  "assets/img/svg/logo/header-logo-transparent-mobile.svg": "fJmOaLYBS2JR3MW-GP6-IpCcQtgX3Lvci89yiwezGqU",
  "assets/img/svg/logo/logo-colorful.svg": "P_FO38USNnXz7xHlYKjI6-nS3Fu_U1VMkJLjl1LBbLc",
  "assets/img/svg/logo/logo-link-desktop.svg": "hUbeBQEwBCSi13ocE8SYW4LQbqBM3ihmLhOxrYOatcY",
  "assets/img/svg/logo/logo-link-mobile.svg": "NOTApGY7ukYOBOrePhCXmgYmsKHRMdHkciOZYfxuSDo",
  "assets/img/svg/logo/logo-white.svg": "RxjvvyuCYCPff9sWoji7_sIp4Ds_x85-RR1fUWS2wuY",
  "assets/img/svg/sprite.svg": "TPOh9COKl8j6dCU8acUDpzY-ivRlWmvDgIiqVyRkA-4",
  "assets/img/svg/whatsapp.svg": "D9EnL1-qaTKmjV_I2VzPXyi7dZbpkabomAAaNQ4FPvw",
  "assets/industries-content/img/banner-icms-comgas.png": "FEeE01uuNI3N1erytIUPiQyCBSAJvM3RMwdD8zps9_0",
  "assets/industries-content/img/banner-icms-comgas.webp": "qCG_X_9H8cM5nNFKrj_YJAuU107cUYMs8xQ7lFg7jrc",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado-mobile.png": "yttaoqSHB1zhJWPx6fcCGPAvWt8MB2tNPvfkIFfjWNs",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado-mobile.webp": "G4cC85n-TQBULNG9pJu2c56OW74VXntlm4yDZFOajTU",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado.png": "yttaoqSHB1zhJWPx6fcCGPAvWt8MB2tNPvfkIFfjWNs",
  "assets/industries-content/img/engenheiro-com-api-seguranca-industrial-com-gás-encanado.webp": "G4cC85n-TQBULNG9pJu2c56OW74VXntlm4yDZFOajTU",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial-mobile.png": "WpQZ74GpeDTziqW1tUFfrg6P13ZT9dI0ydJ9guLM3yE",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial-mobile.webp": "pQuAieZjvXwkpiVDefBeUe5vCc9Pq9h8egQnebLZEr4",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial.png": "WpQZ74GpeDTziqW1tUFfrg6P13ZT9dI0ydJ9guLM3yE",
  "assets/industries-content/img/gás-natural-e-sustentabilidade-industrial.webp": "pQuAieZjvXwkpiVDefBeUe5vCc9Pq9h8egQnebLZEr4",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás-mobile.png": "UWdaD-LbVCmlf3cNuNlU7XDR4lepXs0EcnMcW0CZu8Y",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás-mobile.webp": "U3heEPwskcnxEe91_Al6QF4PHa9P4j9ETemsFRsvtQE",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás.png": "UWdaD-LbVCmlf3cNuNlU7XDR4lepXs0EcnMcW0CZu8Y",
  "assets/industries-content/img/maquinários-industriais-com-abastecimento-a-gás.webp": "U3heEPwskcnxEe91_Al6QF4PHa9P4j9ETemsFRsvtQE",
  "assets/industries-content/img/svg/gerador.svg": "3WfCTqj5WnghPnH1vn4RW84qCeOSIGvstWDGHddKm5o",
  "assets/protocol/img/agendamento-casas.png": "okEkwME97BauM5_l8au_9VZIJOnmCjrLl3lnFDQbifw",
  "assets/protocol/img/agendamento-comercio.png": "vLYcKIdVvhDBTxnGYYPAhOL7WbB_QrEYYRgpfEoDgIk",
  "assets/protocol/img/casas.png": "vfDEH_vDCwShGfkX0uizm_DxM6CJASrmuWII-2v_Dac",
  "assets/protocol/img/central-predio.png": "LJVOgHmomQDDzrkofugSyl3fdhN4pZQuOxMmeUJc9Ns",
  "assets/protocol/img/comercio.png": "xOIQdMGn3kQPnJW4bT59bYotqZu4pW-GALKGrOSaTsg",
  "assets/protocol/img/individual-predio.png": "dWgGQ5R4gQ27TbS_aUucoykff58iAuqJzBPMYC-abkQ",
  "assets/protocol/img/industria.png": "D_0XKvfy8hfZR-un2eD-GtmtdSyVaSWSgQfb7U4C4J0",
  "assets/protocol/img/new-home.png": "cRmzrxhKMJ9bUkDVHqi3EYGJ7XBANWRoDoAACYVC2B4",
  "assets/protocol/img/new-housing.png": "woFZmzv--uZsIeDXHtJX78qf36zlTJhe4owrwUUOVHA",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado-mobile.png": "1C3duX-lG0Kc8-QD_ZjW0kRILh88cCDWR4RVIASgeus",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado-mobile.webp": "IYx6c8byCIL91Bl8RzDpEQ7FHlV_v-MegSn9Rj0v8eg",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado.png": "e7YBzEpVAbM5RCdsbO9heH92fxbBy4i1saZySzvbpWY",
  "assets/residential-content/img/bairro-planejado-casas-gas-encanado.webp": "e7YBzEpVAbM5RCdsbO9heH92fxbBy4i1saZySzvbpWY",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida-mobile.png": "_VQY4S1Z6M7WovwEqJjkGpSsuJ-agM7BHdAGlySUQhU",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida-mobile.webp": "emjA5wrNu5bgEgmyjTbrJ5MHdcgbFEW2JRDR0kU9Fe4",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida.png": "2qn1fytFwiWItpgOiqio6eT6Qjy8GuF_9UerhCyzh4Y",
  "assets/residential-content/img/casa-recem-construída-com-piscina-aquecida.webp": "2qn1fytFwiWItpgOiqio6eT6Qjy8GuF_9UerhCyzh4Y",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gás-encanado-mobile.png": "_u3Txvpfn4BZDf3_yr7t6NiMZpXUgCNWat4919qf35I",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gás-encanado-mobile.webp": "_u3Txvpfn4BZDf3_yr7t6NiMZpXUgCNWat4919qf35I",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gás-encanado.png": "_txAi5cGhiWyIFAmE85KITE6ZF7pPvl4imOm7QpV6cI",
  "assets/residential-content/img/casal-feliz-preparando-churrasco-em-churrasqueira-a-gás-encanado.webp": "_txAi5cGhiWyIFAmE85KITE6ZF7pPvl4imOm7QpV6cI",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado-mobile.png": "eZ3PZGYEliJBbw8QDXu3esB-cqHunRH1WtYAZNMDDT4",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado-mobile.webp": "QMY9oYAt86bDElfqdS-j59Jp2Ngy4lEyiPzEFvWCA1c",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado.png": "loKUF1u4Ji_V36fg-DN3AncItaDZmOI-cfltrgDRKMg",
  "assets/residential-content/img/casas-de-rua-construidas-com-gas-natural-encanado.webp": "loKUF1u4Ji_V36fg-DN3AncItaDZmOI-cfltrgDRKMg",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado-mobile.png": "YH_vRSwJM4_jCCnSarQcdtevUfDnviTibWstCZxG8C0",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado-mobile.webp": "YH_vRSwJM4_jCCnSarQcdtevUfDnviTibWstCZxG8C0",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado.png": "InNwcPETPQVKnMXTxl9rGQ_S9gzbwThBFNhd3hSW724",
  "assets/residential-content/img/chuveiro-com-instalação-de-gás-encanado.webp": "InNwcPETPQVKnMXTxl9rGQ_S9gzbwThBFNhd3hSW724",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural-mobile.png": "zX_D6yGPseu64LfzeX5zJK6zinvdLHhqbLL_F2VQyNo",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural-mobile.webp": "uhGpUccwa1PbsaoMsm_Bs5bVHvmCoOqlGTF-IheVyB0",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural.png": "mxbcuF9_6zJzlvlZa9MdAkaIAgE5zI2i7XvPwFKfEfE",
  "assets/residential-content/img/condominio-de-casas-novas-abastecidas-com-gas-natural.webp": "mxbcuF9_6zJzlvlZa9MdAkaIAgE5zI2i7XvPwFKfEfE",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado-mobile.png": "BNRqKVwjXpvzHNZpm_mtgSA69Y0yWforuujcov7ElZg",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado-mobile.webp": "-RCkTpKaZ22d1C6zq-W1695rDDs8TZMovIxjQsIpxWc",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado.png": "5RwxQg6qLZU23TjXLY-RKTVzMuXTTQzhzBdmC_OU9eU",
  "assets/residential-content/img/condominio-padrao-gas-natural-encanado.webp": "5RwxQg6qLZU23TjXLY-RKTVzMuXTTQzhzBdmC_OU9eU",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural-mobile.png": "0UFYbC5y-L8CZj5H3oVwSPjqEVhpsAQjxJRY7C92Jbw",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural-mobile.webp": "0UFYbC5y-L8CZj5H3oVwSPjqEVhpsAQjxJRY7C92Jbw",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural.png": "3H709oMlnRZkJX6dO5vgNfEjQW2HV--OGunV-g4xJt8",
  "assets/residential-content/img/familia-feliz-brincando-em-piscina-aquecida-a-gas-natural.webp": "3H709oMlnRZkJX6dO5vgNfEjQW2HV--OGunV-g4xJt8",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado-mobile.png": "FHfG0kKJKfHn1Oc3T2Ka9IGo0RE7zCyEDw1AmEX-0i0",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado-mobile.webp": "FHfG0kKJKfHn1Oc3T2Ka9IGo0RE7zCyEDw1AmEX-0i0",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado.png": "R6oOlAAQkQsRYJwMUawR6g3ljN7ktfinMbU0s7P98rQ",
  "assets/residential-content/img/homem-prepara-refeicao-em-fogao-a-gas-encanado.webp": "R6oOlAAQkQsRYJwMUawR6g3ljN7ktfinMbU0s7P98rQ",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias-mobile.png": "Nd0zrwbHz43mHNTtzFB9Qwdras4B1rSSraG4gQIqchU",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias-mobile.webp": "biD0Gx79--fmpwmRRTLa-X4_byhGH1B49o21V9JS9G0",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias.png": "dXsQAzbuFo-3kk2wDY0kF0zFmbnHgQ6blFFnNWAJc7k",
  "assets/residential-content/img/instalação-de-sistema-a-gas-para-residencias.webp": "dXsQAzbuFo-3kk2wDY0kF0zFmbnHgQ6blFFnNWAJc7k",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás-mobile.png": "CL9LobHO8-1hlSC78kHcULJbhmThENsIQBdLWlp7lqc",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás-mobile.webp": "06XbznsjzeGpvCETMs3dxmz9-0KJoEPkWjVrbhvTY4U",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás.png": "Maz0OWxJfUQDzo4mtgW2JJWvuhlNg6LTN4Y2qCHmRoc",
  "assets/residential-content/img/mãe-e-filha-em-casa-com-aquecimento-a-gás.webp": "Maz0OWxJfUQDzo4mtgW2JJWvuhlNg6LTN4Y2qCHmRoc",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas-mobile.png": "5WfdWrV_B1oKJUxmehQWJV5k09cW1wiujGQaLb3hTqQ",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas-mobile.webp": "tpBB3CyjZUzvGPbqGVd80dfRU3bTWahoKu8GoooABpo",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas.png": "V7b4UCur8E4tmVkuylJbx-FoCUo7tfIzUurH7bVihQY",
  "assets/residential-content/img/piscina-residencial-climatizada-aquecimento-gas.webp": "V7b4UCur8E4tmVkuylJbx-FoCUo7tfIzUurH7bVihQY",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural-mobile.png": "RfYbQFKwRw31xB8H1H1dxTKYXB4iRVxJ-x3GxyDN4d8",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural-mobile.webp": "7ts-BnEl7vf4DoiwVadUTjefFWYo91KSY1T5H0vtg0k",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural.png": "qK7i2mdTr4ZAyyoKYgyaFqOTuWdQYLL7pcpIXyy2UOg",
  "assets/residential-content/img/residencia-com-piscina-aquecida-gas-natural.webp": "qK7i2mdTr4ZAyyoKYgyaFqOTuWdQYLL7pcpIXyy2UOg",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias-mobile.png": "EpUtdaCk1_YhigQrg2EO_Xn1awHQhSy2ZoBREPm1WSg",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias-mobile.webp": "BMD7vS0JXuBTU144Cf0FEjJQhW5U_8roy6QETOKWkRo",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias.png": "fEiQUq5wH788CXgFsMBXhhD7OEbXHQ3UNQe6bIA9ZHI",
  "assets/residential-content/img/sistema-gas-encanado-em-residencias.webp": "fEiQUq5wH788CXgFsMBXhhD7OEbXHQ3UNQe6bIA9ZHI",
  "assets/residential-content/img/svg/benefits-fold-bg-left-2k.svg": "gS7LINk81js_gk4DP--DtT6stwGZsCDvjSWdY4RJV9E",
  "assets/residential-content/img/svg/benefits-fold-bg-left-desktop.svg": "SNddrZH-ycwyG99CpV0PcJlAqmerLsDSo_QLt7KTJjc",
  "assets/residential-content/img/svg/benefits-fold-bg-left.svg": "_f6ZAZsZ_ogj3fe6OuOujf54sBpfJsv4Z5aTcxAVcXY",
  "assets/residential-content/img/svg/benefits-fold-bg-right-2k.svg": "HFvjx35YkU1UJ0TItN3_xAUaVKRzlx2GCOX_7HS7dgA",
  "assets/residential-content/img/svg/benefits-fold-bg-right-desktop.svg": "B6LFZTmTQ31_DylIAZhVxoCzwoaZefATX9YE3YxJtCE",
  "assets/residential-content/img/svg/benefits-fold-bg-right.svg": "WqQLRaeO90Kx1Ot4xSdc9MZOMtMAqtGpa-Bw4DrlgFg",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natura-mobile.png": "BftqPmtjafQ8lqjWMJI7hYHmuGasza__eps-xzHKcxc",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natura-mobile.webp": "SVFMgITJc8l87VdmP77c3xx8FbLi6vbbDMYHVO22lcg",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natura.png": "UDavwppxXKPOfj-6sYLA_p7YwyeSGChNAg6tW6ILdlI",
  "assets/residential-content/img/trabalhadores-analisando-casa-residencial-recem-construida-com-gas-natura.webp": "UDavwppxXKPOfj-6sYLA_p7YwyeSGChNAg6tW6ILdlI",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento-mobile.png": "B_MiKsbxIt717Rhk0eD15Ik5H14o5QU4kzkKQb10DiU",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento-mobile.webp": "8WZRb9JCcR7FNT65oFbzGJYN_H1lV61sJYBGtruFitg",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento.png": "vEDaQFAUroOu3ecOvqHX4cW1w4Xs6ewBWKMVSfeMtBY",
  "assets/residential-content/img/trabalhadores-em-obra-de-apartamento-com-sistema-de-aquecimento.webp": "vEDaQFAUroOu3ecOvqHX4cW1w4Xs6ewBWKMVSfeMtBY",
  "assets/scheduling/img/passo-1.png": "V1eVKd_gWEzW7uWndD7VhIKM4W_JL9QigPI3eDzGHyw",
  "assets/scheduling/img/passo-1.webp": "N9lfag2AME-ibxPliw71KHtxfmh5R-U4fLYjNU3SW5E",
  "assets/scheduling/img/passo-2.png": "9m2jmqlkz7usavfzUbYKGWw9CI-l446uYb0G9P_-qvY",
  "assets/scheduling/img/passo-2.webp": "sTn7vOWYc0fZYHtH4158zAYMrMYHccberHWp57p0JCc",
  "assets/scheduling/img/passo-3.png": "dFHoi2ZC57Q_hpsJmkkZ3nz3YamydI-qdFQimequrbI",
  "assets/scheduling/img/passo-3.webp": "UQIqfR-3vqFnV4YxpdpCo3a1cY2ThD6wen9kYHoZQ7Q",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-mobile.png": "Y9tNPyx7HcMyGoOgUj4PqetIPSZBSGEV_7Zr0QDVxNg",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada-mobile.webp": "-u6rHhA1UNAl-dbGLcduxtcrnsfMT_3QMoH64kfvyqI",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada.png": "L4CmGevTBA2Wi362KAI8T2onkCsjJj_gQgcxcABBgfE",
  "assets/swimming-pool-content/img/condomínio-residencial-com-piscina-climatizada.webp": "Bj07NKA1__y-f4GfJKYQrfp3TWTO19Ta9bSPJ-uwduM",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás-mobile.png": "01XE7NSzHmdij3shielFZ45_M0DbbuHgtlnoG7A1o80",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás-mobile.webp": "fqfMzLvVJq-ODiMOcGk_kdLOqXIMR3ne3S0PxJ7TNrE",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás.png": "AMFJOsyvJle5XiNbeitFfEpFy26qdJGKlGVR2Po86Ps",
  "assets/swimming-pool-content/img/família-em-momento-de-lazer-na-piscina-aquecida-a-gás.webp": "CaOBgMkhmGM0x_OY6CiBAYSzLJ972X0u4rArWt59uNM",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural-mobile.png": "uK6Ty8Iu-M20W11a067xUKExVK0aLJhKr0Llvw-XEI0",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural-mobile.webp": "J2UeX3CcMI5kpjt5asL4DMCpcfAmryLvhyhCCIR4iRc",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural.png": "ZG9PWriL5mSSZwiKXZuTAUF1E8ECZyibMqGHIpfG1ig",
  "assets/swimming-pool-content/img/hall-de-hotel-com-aquecimento-a-gás-natural.webp": "BLNvh_bFSzitJ58wBsfOURfUmYQV7bchrY8BPqxE8gE",
  "assets/swimming-pool-content/img/piscina-aquecida-gas-para-família-mobile.png": "G7HGP1zCn4MC8wSWJAqzNJZM53uWu7akHdXPLC2mJdw",
  "assets/swimming-pool-content/img/piscina-aquecida-gas-para-família-mobile.webp": "A71XrHiRfMsc2Ycl3L38FqxUDPPLBqfFq-9vF8Z7-8M",
  "assets/swimming-pool-content/img/piscina-aquecida-gas-para-família.png": "E5XAxZXSMUZkHwnxUC3-8cdrdSpgfjtbw4gPbRzfiZM",
  "assets/swimming-pool-content/img/piscina-aquecida-gas-para-família.webp": "FYW89lbDRhn5oMRL-3hrc2XX0NtGnMRHDzK4eikC_Do",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias-mobile.png": "m0_zDNPE4bxxwA_DzPU6cnADl7bJVObm7fAlGi_0dcA",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias-mobile.webp": "DXWIh-a6iZzd0cTHBDFk-i7bQNXCjsyPP3Pn4uKwQSw",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias.png": "h-soxptqBy1im60aJ31rknGxSr0RKetYyg6vvcU4ky8",
  "assets/swimming-pool-content/img/sistema-de-aquecimento-de-piscina-em-clubes-e-academias.webp": "5M5d8FF0Yf9uyZuEh5Tx93HhbUcoOV3GnAvljUDIUds",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida-mobile.png": "PTNAw13YO7rkD9YGTnebNhbmAKiC3WvB23-Yk98GuSQ",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida-mobile.webp": "WSHZRmTl2Vt2zhHQSE1R40YYVMvX5WrDrEWY0QZGzgo",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida.png": "pBRKPH3FH-ASMKcUoYmaX6wk1a1hsZHtW3ZJHU0JeiM",
  "assets/swimming-pool-content/img/vista-de-pôr-do-sol-em-fazenda-com-piscina-aquecida.webp": "trahbWbYyCLGLXaRBH6pWPzbHN2VweTRMhpbLJYc1GA",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-1366.png": "ncwJ7L-3dEzO_9JKoFVs61_6LvB2uDx_GbOMUBj4HMY",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-1366.webp": "ncwJ7L-3dEzO_9JKoFVs61_6LvB2uDx_GbOMUBj4HMY",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-2K.png": "HBqUoY7RlxOEaNA1LKmTnKeHffahfFzShLb-1VJP6Lc",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-2K.webp": "HBqUoY7RlxOEaNA1LKmTnKeHffahfFzShLb-1VJP6Lc",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-FHD.png": "Q327qKFsva9NVYWODVo_3ZXahD8WFnn-A-DzEevT5jM",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-desktop-FHD.webp": "Q327qKFsva9NVYWODVo_3ZXahD8WFnn-A-DzEevT5jM",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-mobile.png": "h4T-cK6I9dHqgT1ueZnC2attdEy3bvwg1oIUQ0RvNrk",
  "assets/vehicles-content/img/caminhao-de-carga-que-faz-uso-de-gás-natural-veicular-em-estrada-com-pôr-do-sol-mobile.webp": "h7uHSXs1NqTfyd2M8gHbtsdzb30k7zovINEtyNz5ENk",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-1366.png": "eTCUsNQkrzmgYxBXcnLDX2xzhJG_GX9rL1JkUV9ovyU",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-1366.webp": "eTCUsNQkrzmgYxBXcnLDX2xzhJG_GX9rL1JkUV9ovyU",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-2K.png": "HchF9JUxiQ8K2pbMNYPRBKY_PZf4ZUXuPo6IsgSPXiI",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-2K.webp": "HchF9JUxiQ8K2pbMNYPRBKY_PZf4ZUXuPo6IsgSPXiI",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-FHD.png": "BoZ7V00Jf7Q_Y2jMDM12raQymKZsdz4-c-LZHqvTaOw",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-desktop-FHD.webp": "BoZ7V00Jf7Q_Y2jMDM12raQymKZsdz4-c-LZHqvTaOw",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-mobile.png": "m9ynEgqtxUJmIHzbW6D5Lz6pkYaJmtid6JFzgQfE2lg",
  "assets/vehicles-content/img/carro-compacto-em-movimento-movido-com-gnv-mobile.webp": "aEgFEfpGyiJUa3-SxWFI28yMw19rgmskgz22lZ3iaGs",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste.png": "xi9XDgGAFo5RXHMHk06t4FWlJjmSLb8-AKrszglOWUA",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-contraste.webp": "LFUy-3LEmaeldn9KAQmqyDiNh_V_2oiYaPQskatM-Co",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop-contraste.png": "E596IPv3abiCmTkDYhJ1nWyATc5j1Nyp9z3a-SP8VYQ",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop-contraste.webp": "mNkxZrY2ZVP10xyYt4ReUmdzjJ2EBCw0KYfRLvJueIA",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop.png": "l_qgD83bkYDnuxFzPq7K3lh7x-JcmqkGw0OmTCNYHJs",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv-desktop.webp": "jk5V3srQQGaa34yQmf_hcS-4MkSYr138RhMbYScNmow",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv.png": "5eZWruqnBTd2YuMCcVAFo4uvexZsji27G-u9UxNh_5M",
  "assets/vehicles-content/img/cliente-comgas-dirigindo-carro-movido-a-gnv.webp": "to9dKjfmxUCPN9JBTeB7V7Q5tudq1gDCDx_btwry0L4",
  "assets/vehicles-content/img/google-maps.png": "GJ4O8O3dE1tNVZsFocgdsvvWS12IXGfS04AblcOqzBw"
}