<figure *ngIf="figCaption; else pictureOnly">
  <ng-container [ngTemplateOutlet]="pictureOnly"></ng-container>
  <figcaption>
    {{ figCaption | translate }}
  </figcaption>
</figure>

<ng-template #pictureOnly>
  <picture>
    <ng-container *ngIf="breakpoints">
      <ng-container
        *ngFor="let breakpoint of breakpoints | keyvalue | orderBy : '-key'"
      >
        <ng-container *ngIf="breakpoint.key !== '0'">
          <source
            *ngIf="breakpoint.value.webp"
            [attr.srcset]="breakpoint.value.webp | fileHash"
            [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
            type="image/webp"
          />
          <source
            *ngIf="breakpoint.value.png"
            [attr.srcset]="breakpoint.value.png | fileHash"
            [attr.media]="'(min-width: ' + breakpoint.key + 'px)'"
          />
        </ng-container>
      </ng-container>
    </ng-container>

    <source
      *ngIf="webp?.desktop"
      type="image/webp"
      media="(min-width: 64em)"
      [srcset]="webp?.desktop | fileHash"
    />
    <source
      *ngIf="png?.desktop"
      media="(min-width: 64em)"
      [srcset]="png?.desktop | fileHash"
    />

    <source
      *ngIf="breakpoints?.['0']?.webp; else defaultMobileWebp"
      type="image/webp"
      [srcset]="breakpoints?.['0']?.webp | fileHash"
    />
    <ng-template #defaultMobileWebp>
      <source
        *ngIf="webp?.mobile"
        type="image/webp"
        [srcset]="webp?.mobile | fileHash"
      />
    </ng-template>

    <img
      class="img-fluid"
      [ngClass]="{
        'w-100': fullWidth,
        'h-100': fullHeight,
        'object-fit-cover': cover
      }"
      [src]="
          (png?.mobile | fileHash) || (breakpoints?.['0']?.png | fileHash)
        "
      [alt]="alt || '' | translate"
      [title]="title || '' | translate"
      [loading]="lazy ? 'lazy' : 'eager'"
    />
  </picture>
</ng-template>
