import { NgIf } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformCheckerService } from '@shared/services';

@Component({
  selector: 'qsc-tooltip',
  standalone: true,
  imports: [NgIf, TranslateModule],
  templateUrl: './tooltip.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent implements AfterViewInit {
  @Input() content = 'content';
  @Output() clickEvent = new EventEmitter();
  @Output() hoverEvent = new EventEmitter();
  @Output() focusEvent = new EventEmitter();
  tooltipInstance: any;
  isActiveByClick = false;
  isActiveByHover = false;

  @ViewChild('tooltip') tooltip?: ElementRef;

  constructor(private readonly platformChecker: PlatformCheckerService) {}

  ngAfterViewInit(): void {
    this.startTooltip();
  }

  private startTooltip(): void {
    if (this.platformChecker.isBrowser()) {
      setTimeout(() => {
        if (this.tooltip) {
          this.tooltipInstance = new bootstrap.Tooltip(
            this.tooltip.nativeElement
          );
        }
      }, 1500);
    }
  }

  click(): void {
    this.clickEvent.emit();
    if (this.isActiveByHover) {
      this.isActiveByHover = false;
    }
    this.isActiveByClick = true;
  }

  hover(): void {
    if (this.isActiveByClick) return;
    this.hoverEvent.emit();
    this.isActiveByHover = true;
  }

  leave(): void {
    this.isActiveByHover = false;
  }

  focus(): void {
    this.focusEvent.emit();
  }

  blur(): void {
    this.isActiveByClick = false;
  }
}
