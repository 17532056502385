/* eslint-disable no-useless-escape */
import { inject, Injectable, Renderer2 } from '@angular/core';
import { IChatMetadata } from '@models/chat/chat-metadata';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { GoogleTagManagerService } from '@core/index';
import { UrlService } from '../url/url.service';
import { BackToTopService } from '../back-to-top/back-to-top.service';
import { FloatingWrapperService } from '../floating-wrapper/floating-wrapper.service';
import { environment } from '@env';
import { DOCUMENT } from '@angular/common';
import chatbotJson from '@assets/data/chatbot.json';
import { removeBlankAttributes } from '@shared/utils';
import { FooterAnalytics } from '@models/analytics';
import { IframeStylerService } from '../iframe-styler/iframe-styler.service';
import { BootstrapService } from '@core/ui/bootstrap/bootstrap.service';

@Injectable({
  providedIn: 'root',
})
export class ChatService {
  private readonly WEB_MESSENGER_CONTAINER = '#web-messenger-container';
  private readonly CHAT_MESSAGE = '#chat-message';

  private integrationId = environment.suncoId;
  private window = inject(DOCUMENT).defaultView;
  private showChatMessage = new BehaviorSubject<boolean>(false);

  constructor(
    private readonly googleTagManager: GoogleTagManagerService,
    private readonly url: UrlService,
    private readonly backToTop: BackToTopService,
    private readonly floatingWrapper: FloatingWrapperService,
    private readonly translateService: TranslateService,
    private readonly renderer: Renderer2,
    private readonly bootstrap: BootstrapService,
    private readonly iframeStyler: IframeStylerService
  ) {}

  loadChat(): void {
    this.injectHeadScript(this.integrationId);

    const chatbotData = {
      integrationId: this.integrationId,
      ...chatbotJson,
    };

    if (this.getSmoochInstance()) {
      this.getSmoochInstance().init(chatbotData);
      if (typeof this.getSmoochInstance().on === 'function') {
        this.getSmoochInstance().on(
          'conversation:added',
          (_: any, data: any) => {
            this.addConversationMetadata(data.conversation);
          }
        );
        this.getSmoochInstance().on('widget:opened', this.handleWidgetOpened);
        this.getSmoochInstance().on('widget:closed', this.handleWidgetClosed);
        this.getSmoochInstance().on('ready', this.handleWidgetReady);
      }
    }

    this.getChatMessageStatus().subscribe((status) => {
      this.adjustChatIframeHeight(status);
    });
  }

  applyChatIframeStyle(): void {
    this.iframeStyler.applyStyle(
      this.WEB_MESSENGER_CONTAINER,
      this.bootstrap.isMatched('lg')
        ? 'assets/web-messenger/cris-desktop.css'
        : 'assets/web-messenger/cris-mobile.css'
    );
  }

  private adjustChatIframeHeight(status: boolean): void {
    const iframe = document.querySelector(this.WEB_MESSENGER_CONTAINER);
    if (iframe) {
      this.renderer.setStyle(iframe, 'bottom', 0);
      this.renderer.setStyle(iframe, 'right', 0);
      this.renderer.setStyle(iframe, 'margin-bottom', 0);

      let heightWhenOpen = '100%';

      if (this.bootstrap.isMatched('md')) {
        heightWhenOpen = '504px';
      }

      if (this.bootstrap.isMatched('xl')) {
        heightWhenOpen = '664px';
      }

      if (status) {
        this.renderer.setStyle(iframe, 'height', '200px');
      } else {
        this.renderer.setStyle(iframe, 'height', heightWhenOpen);
      }
    }
  }

  private injectHeadScript(integrationId: string): void {
    const script = this.window?.document.createElement('script');
    if (!script) return;
    script.type = 'text/javascript';
    script.async = true;
    script.innerHTML = `
      !function(o,d,s,e,f){var i,a,p,c=[],h=[];function t(){var t="You must provide a supported major version.";try{if(!f)throw new Error(t);var e,n="https://cdn.smooch.io/",r="smooch";e="string"==typeof this.response?JSON.parse(this.response):this.response;var o=f.match(/([0-9]+)\.?([0-9]+)?\.?([0-9]+)?/),s=o&&o[1],i=o&&o[2],a=o&&o[3],p=e["v"+s],c=e["v"+s+"."+i+".patch"];if(e.url||p||c){var h=d.getElementsByTagName("script")[0],u=d.createElement("script");if(u.async=!0,a)u.src=c||n+r+"."+f+".min.js";else{if(!(5<=s&&p))throw new Error(t);u.src=p}h.parentNode.insertBefore(u,h)}}catch(e){e.message===t&&console.error(e)}}o[s]={init:function(){i=arguments;var t={then:function(e){return h.push({type:"t",next:e}),t},catch:function(e){return h.push({type:"c",next:e}),t}};return t},on:function(){c.push(arguments)},render:function(){a=arguments},destroy:function(){p=arguments}},o.__onWebMessengerHostReady__=function(e){if(delete o.__onWebMessengerHostReady__,o[s]=e,i)for(var t=e.init.apply(e,i),n=0;n<h.length;n++){var r=h[n];t="t"===r.type?t.then(r.next):t.catch(r.next)}a&&e.render.apply(e,a),p&&e.destroy.apply(e,p);for(n=0;n<c.length;n++)e.on.apply(e,c[n])};var n=new XMLHttpRequest;n.addEventListener("load",t),n.open("GET","https://"+e+".webloader.smooch.io/",!0),n.responseType="json",n.send()}(window,document,"Smooch","${integrationId}","5.6.0");
    `;
    this.window?.document.head.appendChild(script);
  }

  private getSmoochInstance(): any {
    return this.window ? this.window['Smooch'] : null;
  }

  private addConversationMetadata(data: any): void {
    if (!data || Object.keys(data.metadata ?? {})?.length > 0) return;

    const mediaParameters = this.googleTagManager.getMediaParameters();
    if (!mediaParameters) return;

    this.getSmoochInstance().updateConversation(data.id, {
      metadata: {
        api: 'enviaLead',
        servico: 'Enviar Lead',
        payload: JSON.stringify(
          removeBlankAttributes({
            campaign: mediaParameters?.campaign,
            medium: mediaParameters?.medium,
            fbclid: mediaParameters?.fbclid,
            gclid: mediaParameters?.gclid,
            source: mediaParameters?.source,
          })
        ),
      },
    });
  }

  private handleWidgetOpened = (): void => {
    let pageName: string | null = null;
    this.updateChatMessageStatus(false);
    this.backToTop.updateBackToTopStatus(false);
    this.floatingWrapper.updateFloatingWrapperStatus(false);

    if (!pageName) {
      const currentUrlPath = this.url.getCurrentUrlPath();
      pageName = currentUrlPath.replace('/', '');
    }

    this.googleTagManager.sendCustomEvent(
      FooterAnalytics.category.rodape,
      FooterAnalytics.action.chatbot,
      pageName
    );
  };

  private handleWidgetClosed = (): void => {
    this.updateChatMessageStatus(true);
    this.backToTop.updateBackToTopStatus(true);
    this.floatingWrapper.updateFloatingWrapperStatus(true);
  };

  private handleWidgetReady = (): void => {
    this.updateChatMessageStatus(true);

    let conversations = this.getSmoochInstance().getConversations();
    if (conversations && conversations.length > 0) return;

    this.getSmoochInstance()
      .createConversation({
        displayName: 'Comgás',
      })
      .then(() => {
        conversations = this.getSmoochInstance().getConversations();

        if (conversations) {
          for (const conversation of conversations) {
            this.addConversationMetadata(conversation);
          }
        }
      });
  };

  getChatMessageStatus(): Observable<boolean> {
    return this.showChatMessage.asObservable();
  }

  private updateChatMessageStatus(status: boolean): void {
    this.showChatMessage.next(status);
  }

  hideChat(): void {
    const container = document.querySelector(this.WEB_MESSENGER_CONTAINER);
    const containerMessage = document.querySelector(this.CHAT_MESSAGE);
    if (container) {
      container.setAttribute('style', 'display: none;');
    }
    if (containerMessage) {
      containerMessage.setAttribute('style', 'display: none;');
    }
    this.updateChatMessageStatus(false);
  }

  showChat(): void {
    const container = document.querySelector(this.WEB_MESSENGER_CONTAINER);
    const containerMessage = document.querySelector(this.CHAT_MESSAGE);
    if (container) {
      container.setAttribute('style', 'display: block;');
    }
    if (containerMessage) {
      containerMessage.setAttribute('style', 'display: block;');
    }
    this.updateChatMessageStatus(true);
  }

  openChat(): void {
    this.showChat();
    this.getSmoochInstance().open();
  }

  openChatWithHumanSupport(metadata: IChatMetadata): void {
    this.showChat();
    metadata.payload = JSON.stringify(removeBlankAttributes(metadata.payload));
    this.getSmoochInstance().createConversation({
      displayName: 'Comgás',
      description: metadata.servico,
      metadata: metadata,
      messages: [
        {
          text: this.generateInitialMessage(metadata.status),
          type: 'text',
        },
      ],
    });
    this.getSmoochInstance().open();
  }

  private generateInitialMessage(status: number) {
    return this.translateService.instant('@CHAT-MESSAGE-' + status);
  }
}
