[
	{
		"name": "PAULISTA DE AMERICANA",
		"localName": "Ale",
		"city": "Americana",
		"address": "Av. Paulista, 499",
		"area": "Vila Nossa Sra. de Fatima",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.73763010182853",
		"longitude": "-47.32031432286114",
		"postalCode": "13478-580"
	},
	{
		"name": "AUTO POSTO E RESTAURANTE APARECIDA LTDA",
		"localName": "Posto Aparecida",
		"city": "Aparecida",
		"address": "Rod. Pres. Dutra, SN - KM 71",
		"area": "Padre Vitor",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.8340544981463",
		"longitude": "-45.21815506930388",
		"postalCode": "12570-000"
	},
	{
		"name": "LAVAJATO AQUARIUS LTDA",
		"localName": "Lavajato Aquarius Ltda",
		"city": "Araras",
		"address": "Av. Padre Alarico Zacarias, 70",
		"area": "Jardim Belvedere",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.360641885647766",
		"longitude": "-47.387966367247486",
		"postalCode": "13601-200"
	},
	{
		"name": "POSTO DE SERVIÇOS DUQUE ALPHAVILLE LTDA.",
		"localName": "Posto Shell - Posto Servicos Duque Alphaville Ltd",
		"city": "Barueri",
		"address": "Al. Rio Negro, 600",
		"area": "Alphaville",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.500399712281506",
		"longitude": "-46.8476969601254",
		"postalCode": "06454-000"
	},
	{
		"name": "AUTO POSTO DUQUE BARUERI LTDA.",
		"localName": "Posto BR + GNV | Rede Duque - Barueri",
		"city": "Barueri",
		"address": "Rua Campos Sales, 934",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.503837604970972",
		"longitude": "-46.877407976554224",
		"postalCode": "06420-390"
	},
	{
		"name": "FLOR DO VERA",
		"localName": "Auto Posto Flor do Vera",
		"city": "Caieiras",
		"address": "Rod. Pres. Tancredo de Almeida Neves, 4100",
		"area": "Jardim Monte Alegre",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.33205450207771",
		"longitude": "-46.74797252496219",
		"postalCode": "07700-000"
	},
	{
		"name": "AUTO POSTO LUMA DE CAJAMAR LTDA.",
		"localName": "Auto Posto Luma de Cajamar Ltda.",
		"city": "Cajamar",
		"address": "Av. Arnaldo Rojek, 569",
		"area": "Jordanesia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.334535886626274",
		"longitude": "-46.83626109586706",
		"postalCode": "07760-000"
	},
	{
		"name": "AUTO POSTO COLINAS VERDE LTDA",
		"localName": "Auto Posto Colinas Verde Ltda",
		"city": "Cajamar",
		"address": "Avenida Jordano Mendes, 246",
		"area": "Jordanesia",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.333674503093246",
		"longitude": "-46.83797860025764",
		"postalCode": "07776-480"
	},
	{
		"name": "POSTO GARCIA DE CAMPINAS LTDA",
		"localName": "Posto Garcia de Campinas Ltda",
		"city": "Campinas",
		"address": "Rod. Anhanguera, Km 99, S/n",
		"area": "Jardim Eulina",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.88828280271208",
		"longitude": "-47.11230901955615",
		"postalCode": "13063-000"
	},
	{
		"name": "AUTO POSTO JARDIM DO TREVO LTDA.",
		"localName": "Auto Posto Jardim do Trevo Ltda.",
		"city": "Campinas",
		"address": "Av. Benedito de Campos, 193",
		"area": "Jardim do Trevo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.92954363989503",
		"longitude": "-47.07063049055555",
		"postalCode": "13024-500"
	},
	{
		"name": "RODOCAMP AUTO SERVICE",
		"localName": "Posto Ipiranga GNV - Rodocamp Auto Service",
		"city": "Campinas",
		"address": "Av. Mercedez Benz 01 Q1 Qt 8907",
		"area": "Distrito Industrial",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.00245014812015",
		"longitude": "-47.11295415640514",
		"postalCode": "13054-750"
	},
	{
		"name": "POSTO ANDORINHAS LTDA",
		"localName": "Posto Andorinhas Ltda",
		"city": "Campinas",
		"address": "Av. José de Sousa Campos, 2101",
		"area": "Cambuí",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.88763468770766",
		"longitude": "-47.044805967968955",
		"postalCode": "13025-320"
	},
	{
		"name": "BG GNV DO BRASIL LTDA -POSTO FUTURO",
		"localName": "BG GNV do Brasil Ltda - Posto Futuro",
		"city": "Campinas",
		"address": "Av. Albino J. B. de Oliveira, 360",
		"area": "Barão Geraldo",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.835378495877148",
		"longitude": "-47.079060648572906",
		"postalCode": "13084-008"
	},
	{
		"name": "FLAMINGO",
		"localName": "Posto Shell - Flamingo",
		"city": "Campinas",
		"address": "Av. John Boyd Dunlop, 460",
		"area": "Vila São Bento",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.910236792091627",
		"longitude": "-47.09671497763888",
		"postalCode": "13033-720"
	},
	{
		"name": "NN AMOREIRAS",
		"localName": "Posto Ipiranga",
		"city": "Campinas",
		"address": "Av. das Amoreiras, 3635",
		"area": "Vila Mimosa",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.933538927016663",
		"longitude": "-47.091378109977",
		"postalCode": "13050-035"
	},
	{
		"name": "AUTO POSTO BARAO DE ITAPURA CAMPINAS LTDA",
		"localName": "Auto Posto Barão de Itapura Campinas Ltda",
		"city": "Campinas",
		"address": "Av. Barão de Itapura, 641",
		"area": "Botafogo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.89770270461698",
		"longitude": "-47.068909906939105",
		"postalCode": "13020-431"
	},
	{
		"name": "AUTO POSTO GLOBAL",
		"localName": "Auto Posto Global",
		"city": "Campinas",
		"address": "R Marques De Valenca 60 20 Jd",
		"area": "Santa Genebra",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.84456489001857",
		"longitude": "-47.06933231776149",
		"postalCode": "13080-230"
	},
	{
		"name": "AUTO POSTO 2002",
		"localName": "Auto Posto 2002",
		"city": "Campinas",
		"address": "Av. Princesa D'Oeste, 2002",
		"area": "Jardim Paraíso",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.906208565404373",
		"longitude": "-47.04598965238297",
		"postalCode": "13100-040"
	},
	{
		"name": "SETE ESTRELAS CAÇAPAVA - FILIAL 19",
		"localName": "Sete Estrelas - Filial 19",
		"city": "Caçapava",
		"address": "GNV - 238 Possui, Av. Henry Nestlé, 190",
		"area": "Vila Antônio Augusto Luiz",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.113199295728318",
		"longitude": "-45.710310916351744",
		"postalCode": "12287-260"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS LTDA TIGRÃO - POSTO 52",
		"localName": "Rede de Postos Sete Estrelas - Posto 52",
		"city": "Caçapava",
		"address": "Av. Henry Nestlé, 3416 - Santa Luzia, Caçapava - SP, 12286-160",
		"area": "Santa Luzia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.123945176496537",
		"longitude": "-45.73069965024969",
		"postalCode": "12286-160"
	},
	{
		"name": "RODOPOSTO TURMALINA LTDA",
		"localName": "Posto Petrobras",
		"city": "Cordeirópolis",
		"address": "SP-330, S/N",
		"area": "Rural",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.453270481093124",
		"longitude": "-47.39804688425418",
		"postalCode": "13490-000"
	},
	{
		"name": "POSTO VILA RICA SÃO CRISTOVÃO",
		"localName": "Posto Vila Rica",
		"city": "Cruzeiro",
		"address": "Av. Minas Gerais, 1200 - II",
		"area": "Retiro da Mantiqueira",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.565344377158183",
		"longitude": "-44.95152968872567",
		"postalCode": "12712-010"
	},
	{
		"name": "SETE ESTRELAS CRUZEIRO - POSTO 39",
		"localName": "Sete Estrelas - Posto 39",
		"city": "Cruzeiro",
		"address": "Av. Rotary, 768",
		"area": "Jardim America",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.58875530138657",
		"longitude": "-44.96257351844674",
		"postalCode": "12702-010"
	},
	{
		"name": "COOPERATIVA LIGUE TÁXI DE CUBATÃO-POSTO EXCLUSIVO COOPERATIVA CLTX(taxi)",
		"localName": "Cooperativa Ligue Táxi de Cubatão",
		"city": "Cubatão",
		"address": "R. José Vicente, 91",
		"area": "Vila Elizabeth",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.876767257300088",
		"longitude": "-46.423222345968796",
		"postalCode": "11505-010"
	},
	{
		"name": "ECOPOSTO  VIA BRASIL ECOVIAS",
		"localName": "Posto Setee - Ecoposto",
		"city": "Diadema",
		"address": "Av. Fábio Eduardo Ramos Esquivel, 2105",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.683636526075972",
		"longitude": "-46.605848232687315",
		"postalCode": "09920-575"
	},
	{
		"name": "POSTO E RESTAURANTE TRÊS GARÇAS",
		"localName": "Posto Petrobras GNV",
		"city": "Guaratinguetá",
		"address": "Rod. Pres. Dutra, Km 59",
		"area": "Piedade",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.781499110415073",
		"longitude": "-45.14954293720886",
		"postalCode": "12524-900"
	},
	{
		"name": "CLUBE DOS 500  (ANTIGO POSTO E RESTAURANTE TRÊS GARÇAS",
		"localName": "Posto Rodogás / GNV",
		"city": "Guaratinguetá",
		"address": "Rod. Pres. Dutra, Km 60",
		"area": "Clube dos Quinhentos",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.789532366237005",
		"longitude": "-45.15829172537739",
		"postalCode": "12524-900"
	},
	{
		"name": "Posto de Serviços Automotivos e Comercio de Combustivel Madri Ltda",
		"localName": "Posto de Serviços Automotivos e Comercio de Combustivel Madri Ltda",
		"city": "Guarulhos",
		"address": "Rua Anton Philips, 119",
		"area": "Pte. Grande",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.490264999142607",
		"longitude": "-46.54662258516252",
		"postalCode": "07030-010"
	},
	{
		"name": "AUTOPOSTO LISBOA",
		"localName": "Auto Posto Lisboa",
		"city": "Guarulhos",
		"address": "Av. Osíres, 21",
		"state": "SP",
		"area": "Cidade Serodio",
		"highFlow": false,
		"latitude": "-23.414268068354332",
		"longitude": "-46.46152083223329",
		"postalCode": "07151-440"
	},
	{
		"name": "CENTRO AUTOMOTIVO RSB LTDA",
		"localName": "Posto Ipiranga - Rsb",
		"city": "Guarulhos",
		"address": "Estr. Pres. Juscelino K. de Oliveira, 390",
		"area": "Jardim Albertina",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.46489585449709",
		"longitude": "-46.41885163537613",
		"postalCode": "07252-000"
	},
	{
		"name": "CENTRO AUTOMOTIVO GUARULHOS (AUTO POSTO MFP POLLI)",
		"localName": "Rede Duque",
		"city": "Guarulhos",
		"address": "Av. Rotary, 710",
		"area": "Vila Izinha",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.486175075456746",
		"longitude": "-46.551113428660024",
		"postalCode": "07042-000"
	},
	{
		"name": "NIKIGAS COMERCIAL  PADRE CELESTINO",
		"localName": "Posto Ipiranga",
		"city": "Guarulhos",
		"address": "R. Padre Celestino, 426",
		"area": "Jardim Santa Francisca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.47315533967381",
		"longitude": "-46.52593250928996",
		"postalCode": "07013-100"
	},
	{
		"name": "CENTRO AUTOMOTIVO ASAS DE CUMBICA LTDA.",
		"localName": "Centro Automotivo Asas de Cumbica Ltda.",
		"city": "Guarulhos",
		"address": "277, Av. Natalia Zarif, 15",
		"area": "Parque Industrial do Jardim Sao Geraldo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.44795692709632",
		"longitude": "-46.494920626695766",
		"postalCode": "07140-040"
	},
	{
		"name": "NOVA TRABALHADORES",
		"localName": "Posto Nova Trabalhadores",
		"city": "Guarulhos",
		"address": "Av. Antônio Bardella, 71",
		"area": "Zona Industrial",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.478428258206677",
		"longitude": "-46.467283635617534",
		"postalCode": "07220-020"
	},
	{
		"name": "NOVO ANEL-CENTRO AUTOMOTIVO CANAIOLO LTDA",
		"localName": "Novo Anel-Centro Automotivo Canaiolo Ltda",
		"city": "Guarulhos",
		"address": "Av. Aricanduva, Pista Norte, 789",
		"area": "Jardim Munhoz",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.505150863545136",
		"longitude": "-46.55869543143589",
		"postalCode": "07033-179"
	},
	{
		"name": "CENTRO AUTOMOTIVO TREVO DE BONSUCESSO LTDA",
		"localName": "Posto Ipiranga",
		"city": "Guarulhos",
		"address": "Estr. Capuava, 10 B",
		"area": "Vila Nova Bonsucesso",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.42493058722755",
		"longitude": "-46.4077907418378",
		"postalCode": "07175-090"
	},
	{
		"name": "ARGETAX GUARULHOS",
		"localName": "Posto Shell",
		"city": "Guarulhos",
		"address": "Av. Rotary, 768",
		"area": "Vila das Bandeiras",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.48508696058353",
		"longitude": "-46.55126836029524",
		"postalCode": "07042-000"
	},
	{
		"name": "CARRETEIRO REVENDEDOR DE PETR. E DER.",
		"localName": "Posto Carreteiro Norte",
		"city": "Guarulhos",
		"address": "Rod. Presidente Dutra, Via Lateral-Norte, Km 221",
		"area": "Porto da Igreja",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.469625751208046",
		"longitude": "-46.51171465420453",
		"postalCode": "07034-000"
	},
	{
		"name": "CECAP GUARULHOS AUTO POSTO LTDA",
		"localName": "Cecap Guarulhos Auto Posto Ltda",
		"city": "Guarulhos",
		"address": "R. Asea, 160",
		"area": "São Roque",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.463443928510607",
		"longitude": "-46.49926635271586",
		"postalCode": "07190-020"
	},
	{
		"name": "POSTO DE SERVIÇOS GAS DUTRA  - (ANTIGO PLATANOS) Atual Posto Puglia",
		"localName": "Posto Serviços Gás Dutra",
		"city": "Guarulhos",
		"address": "Rod. Pres. Dutra, 225",
		"area": "Jardim Santa Francisca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.458910477658343",
		"longitude": "-46.5699474584597",
		"postalCode": "07178-000"
	},
	{
		"name": "AUTO POSTO J.K.",
		"localName": "Auto Posto J.K.",
		"city": "Guarulhos",
		"address": "Estr. Pres. Juscelino K. de Oliveira, 554",
		"area": "Bairro Do Pimentas",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.46893073371708",
		"longitude": "-46.42293774647262",
		"postalCode": "07252-000"
	},
	{
		"name": "NIKIGAS COMERCIAL  GUARULHOS- MATRIZ",
		"localName": "Posto Ipiranga",
		"city": "Guarulhos",
		"address": "Av. Guarulhos, 4389",
		"area": "Jd Ponte Grande",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.50377702095409",
		"longitude": "-46.55183789315555",
		"postalCode": "07032-050"
	},
	{
		"name": "REDE DUQUE - AEROPORTO DE GRU",
		"localName": "Rede Duque",
		"city": "Guarulhos",
		"address": "Aeroporto Internacional de Guarulhos, Terminal 3 Edifício Garagem - Rod. Hélio Smidt, s/n",
		"area": "Cumbica",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.424458398778036",
		"longitude": "-46.475667015627856",
		"postalCode": "07190-100"
	},
	{
		"name": "REMANSO",
		"localName": "Posto Ipiranga",
		"city": "Hortolândia",
		"address": "R. Luís Camilo de Camargo, 840",
		"area": "Jardim Santana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.864692679115134",
		"longitude": "-47.21278865082184",
		"postalCode": "13184-420"
	},
	{
		"name": "CENTRO COMERCIAL DE COMB.HORTOLÂNDIA  POSTO MADRI",
		"localName": "Auto Posto Madri",
		"city": "Hortolândia",
		"address": "Av. Anhanguera, 150",
		"area": "Vila Real",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.858161745073083",
		"longitude": "-47.21596097933018",
		"postalCode": "13183-140"
	},
	{
		"name": "CIDADE DO SOL",
		"localName": "Auto Posto Cidade do Sol",
		"city": "Indaiatuba",
		"address": "Av. Francisco de Paula Leite, nº 2.393",
		"area": "Parque das Nacoes",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.11163900535442",
		"longitude": "-47.21984616956961",
		"postalCode": "13344-610"
	},
	{
		"name": "POSTO RENASCENÇA",
		"localName": "Renascença Serviços Automotivo",
		"city": "Itapevi",
		"address": "Av. Pres. Vargas, 833",
		"area": "Jardim Nova Itapevi",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.538329532854874",
		"longitude": "-46.939060577166835",
		"postalCode": "06694-000"
	},
	{
		"name": "ITAQUAGAS",
		"localName": "Itaquagas Auto posto ltda. Ceprano GNV",
		"city": "Itaquaquecetuba",
		"address": "Rod. João Afonso de Souza Castellano, 455",
		"area": "Monte Belo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.504555856867686",
		"longitude": "-46.34660578420645",
		"postalCode": "08574-060"
	},
	{
		"name": "POSTO TREVO DE ITATIBA",
		"localName": "Posto 7 - Itatiba",
		"city": "Itatiba",
		"address": "Av. Mal. Castelo Branco, 845",
		"area": "Jardim da Luz",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.02272714701964",
		"longitude": "-46.8416133977463",
		"postalCode": "13255-350"
	},
	{
		"name": "J. GAVA AUTO POSTO",
		"localName": "Posto Platina",
		"city": "Itatiba",
		"address": "R. Santo Antônio, 717",
		"area": "Jardim Sao Joao",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.00206768626449",
		"longitude": "-46.82698713774925",
		"postalCode": "13253-200"
	},
	{
		"name": "RETÃO DA DUTRA (ANTIGO RODRIGÃO)",
		"localName": "Posto Petrobrás",
		"city": "Jacareí",
		"address": "Av. Siqueira Campos, 641",
		"area": "Jardim Esper",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.296803481352928",
		"longitude": "-45.95898133611054",
		"postalCode": "12307-000"
	},
	{
		"name": "SETE ESTRELAS JACAREÍ - POSTO 42",
		"localName": "Sete Estrelas",
		"city": "Jacareí",
		"address": "Av. Getúlio Dorneles Vargas, 2300 - Jardim California, Jacareí - SP, 12300-000",
		"area": "Jardim California",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.269753184618406",
		"longitude": "-45.95037705830326",
		"postalCode": "12300-000"
	},
	{
		"name": "FRANGO ASSADO SULESTE JACAREÍ",
		"localName": "Posto Shell - JACAREÍ/SP",
		"city": "Jacareí",
		"address": "Rodoviária Presidente Dutra s/n KM 162 + 500 MTS",
		"area": "Condomínio Lagoinha",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.30091309696113",
		"longitude": "-46.01744413403292",
		"postalCode": "12300-000"
	},
	{
		"name": "AUTO POSTO PPR",
		"localName": "PPR AUTO POSTO GNV",
		"city": "Jaguariúna",
		"address": "KM 138,5, SP-340 - Chácara Santo Antônio, SP, 13915-290",
		"area": "Chácara Santo Antônio",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.590214097475123",
		"longitude": "-47.0011957503772",
		"postalCode": "13915-290"
	},
	{
		"name": "RODOPOSTO MIRANTE",
		"localName": "Posto Ipiranga",
		"city": "Jarinu",
		"address": "Rod. Dom Pedro I",
		"area": "Pte. Alta",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.036718552843634",
		"longitude": "-46.68805744162143",
		"postalCode": "13240-000"
	},
	{
		"name": "GÁS JUNDIAÍ ",
		"localName": "Posto Ipiranga",
		"city": "Jundiaí",
		"address": "Av. Quatorze de Dezembro, 3200",
		"area": "Vila Mafalda",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.225530722292596",
		"longitude": "-46.87404406919036",
		"postalCode": "13206-105"
	},
	{
		"name": "(antigo: SÃO JOÃO  VIA BRASIL) / Auto Posto Caminho dos Passaros Ltda,",
		"localName": "REDE 7 - Auto Posto Caminho dos Pássaros",
		"city": "Jundiaí",
		"address": "Av. Antonio Frederico Ozanan, 3400",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.18659511919829",
		"longitude": "-46.876397502046174",
		"postalCode": "13215-485"
	},
	{
		"name": "WF JUNDIAÍ",
		"localName": "WF Jundiaí",
		"city": "Jundiaí",
		"address": "R. Brasil, 2272",
		"area": "Vila Isabel Eber",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.217594988692607",
		"longitude": "-46.87206814015982",
		"postalCode": "13202-284"
	},
	{
		"name": "CENTRO AUTOMOTIVO CEAJ",
		"localName": "Posto Ipiranga + GNV",
		"city": "Jundiaí",
		"address": "Av. Armando Giassetti, 333",
		"area": "Area 1",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.167575021566414",
		"longitude": "-46.917098923198424",
		"postalCode": "13214-525"
	},
	{
		"name": "FIGUEIRA CIA IPIRANGA",
		"localName": "POSTO IPIRANGA COM GNV VILA ARENS",
		"city": "Jundiaí",
		"address": "R. Lacerda Franco, 180",
		"area": "Vila Arens",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.196345718409194",
		"longitude": "-46.872606165393925",
		"postalCode": "13201-750"
	},
	{
		"name": "POSTO OASIS DE LIMEIRA",
		"localName": "Auto Posto Novo Oasis (Ipiranga) (GNV)",
		"city": "Limeira",
		"address": "Av. Maj. José Levi Sobrinho, 1516",
		"area": "Boa Vista",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.557588944039644",
		"longitude": "-47.38449724535424",
		"postalCode": "13486-190"
	},
	{
		"name": "MODELO DE LIMEIRA",
		"localName": "Modelo de Limeira",
		"city": "Limeira",
		"address": "R. Boa Morte, 33",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.56863340773208",
		"longitude": "-47.399310138076",
		"postalCode": "13480-180"
	},
	{
		"name": "AUTO POSTO BRASIL GÁS SP RJ LTDA",
		"localName": "Posto GNV Brasil",
		"city": "Lorena",
		"address": "Estrada Mun., 117",
		"area": "Jardim Novo Horizonte",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.745644812845022",
		"longitude": "-45.09705031990638",
		"postalCode": "12605-715"
	},
	{
		"name": "FRANGO ASSADO LOUVEIRA",
		"localName": "Posto Petrobras",
		"city": "Louveira",
		"address": "Rod. Anhanguera, S/N - KM 72",
		"area": "Santo Antônio",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.086551209637573",
		"longitude": "-46.975034787928784",
		"postalCode": "13290-000"
	},
	{
		"name": "AUTO POSTO OITENTA",
		"localName": "Shell",
		"city": "Mauá",
		"address": "Av. Cap. João, 614",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.671762303851402",
		"longitude": "-46.458624485490134",
		"postalCode": "09360-120"
	},
	{
		"name": "KLL POSTO DE ABASTECIMENTO E SERVIÇOS",
		"localName": "Posto Ipiranga",
		"city": "Mauá",
		"address": "Avenida Papa João XXIII, Av. Sertãozinho, 2009",
		"area": "Vila Assis Brasil",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.684389242505784",
		"longitude": "-46.46931333663927",
		"postalCode": "09370-800"
	},
	{
		"name": "AUTO POSTO PARQUE CIDADE NOVA LTDA.",
		"localName": "Auto Posto Parque Cidade Nova GNV",
		"city": "Mogi Guaçu",
		"address": "Av. Bandeirantes, 1135",
		"area": "Lot. Parque Cidade Nova",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.349013851215464",
		"longitude": "-46.94695814339219",
		"postalCode": "13845-440"
	},
	{
		"name": "RIO GUAÇU",
		"localName": "Posto Petrobras",
		"city": "Mogi Guaçu",
		"address": "Av. Waldemar Armani, 300",
		"area": "Jd Guacu Mirim Ii",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.376645469991683",
		"longitude": "-46.971225488319064",
		"postalCode": "13844-450"
	},
	{
		"name": "AUTO POSTO RVM MAXI LTDA.",
		"localName": "Postos RVM petronac Mogi Mirim",
		"city": "Mogi Mirim",
		"address": "Rodovia SP 340, Km 159 - Jardim Maria Beatriz, SP, 13803-590",
		"area": "Jardim Maria Beatriz",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.45247837875805",
		"longitude": "-46.981713931652685",
		"postalCode": "13803-590"
	},
	{
		"name": "POSTO MAXXI GNV",
		"localName": "Maxxi Gnv Auto Posto",
		"city": "Mogi Mirim",
		"address": "Av. Alcindo Barbosa, 1335",
		"area": "Parque da Imprensa",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.42285081317574",
		"longitude": "-46.980531928487636",
		"postalCode": "13806-310"
	},
	{
		"name": "JOÃO XXIII AUTOMOBILE",
		"localName": "Posto Petrobras",
		"city": "Mogi das Cruzes",
		"address": "Av. José Meloni, 1280",
		"area": "Vila Mogilar",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.50975093137696",
		"longitude": "-46.19579400840437",
		"postalCode": "08773-120"
	},
	{
		"name": "AUTO POSTO TITANS (ANTIGO VIPAM II)",
		"localName": "Posto Ipiranga",
		"city": "Mogi das Cruzes",
		"address": "Rua Dr. Frederico Renê de Jaegher, 27",
		"area": "Vila Bernadotti",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.53521762146473",
		"longitude": "-46.21909480078416",
		"postalCode": "08735-620"
	},
	{
		"name": "M SUPER COMBUSTIVEIS E LUBRIFICANTES LTDA  ME",
		"localName": "M SUPER COMB E LUBRIF LTDA",
		"city": "Mogi das Cruzes",
		"address": "Av. Lourenço de Souza Franco, 959",
		"area": "Jundiapeba",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.545096475830096,",
		"longitude": "-46.252618016817294",
		"postalCode": "08750-560"
	},
	{
		"name": "POSTO EQUIPE QUALITY",
		"localName": "Posto Shell - Posto Equipe Quality",
		"city": "Mogi das Cruzes",
		"address": "Av. Manoel Bezerra Lima Filho, 260 - Loja 01",
		"area": "Socorro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.51516103845526",
		"longitude": "-46.181577788898544",
		"postalCode": "08780-840"
	},
	{
		"name": "ITAMARATHY AUTO POSTO E ACESSÓRIOS",
		"localName": "Itamarathy Auto Posto Acessórios",
		"city": "Mogi das Cruzes",
		"address": "Av. Francisco Rodrigues Filho, 695",
		"area": "Vila Mogilar",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.51668664925088",
		"longitude": "-46.19012528158586",
		"postalCode": "08773-380"
	},
	{
		"name": "AUTO POSTO SENA LTDA.",
		"localName": "Auto Posto Sena Ltda.",
		"city": "Osasco",
		"address": "Av. Pres. Kenedy, 6160",
		"area": "Vila dos Remedios",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52083114547546",
		"longitude": "-46.74833489699883",
		"postalCode": "06190-160"
	},
	{
		"name": "AUTO POSTO NAÇÕES UNIDAS LTDA- Antigo Auto Posto Altino II",
		"localName": "AUTO POSTO ALTINO II LTDA",
		"city": "Osasco",
		"address": "Rua Werner Battenfeld, 150",
		"area": "Pres. Altino",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.523677767732693",
		"longitude": "-46.762489084032666",
		"postalCode": "06210-170"
	},
	{
		"name": "C. A. PONTE METALICA (ANTIGO SERVACAR COM. E SERV. LTDA. SS OSASCO)",
		"localName": "Loja Shell Select - POSTO SHELL - CENTRO AUT PONTE METALICA DE OSASCO",
		"city": "Osasco",
		"address": "Av. dos Autonomistas, 1987",
		"area": "Vila Yara",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.539673804443016",
		"longitude": "-46.77229563486648",
		"postalCode": "06020-015"
	},
	{
		"name": "BUSSOCABA",
		"localName": "Br",
		"city": "Osasco",
		"address": "Av. Fuad Auada, 236",
		"area": "Bonfim",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.525305152176017",
		"longitude": "-46.77396732180686",
		"postalCode": "06216-000"
	},
	{
		"name": "AUTO POSTO CHIC LTDA",
		"localName": "Posto AUTO POSTO CHIC + GNV",
		"city": "Osasco",
		"address": "R. Maria da Conceição Mattias Santos",
		"area": "Pres. Altino",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52812567118753",
		"longitude": "-46.77310849315289",
		"postalCode": "06216-340"
	},
	{
		"name": "PETROSASCO",
		"localName": "PETROSASCO",
		"city": "Osasco",
		"address": "Av. Getulio Vargas, 1485",
		"area": "JARDIM PIRATININGA",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.50568429337139",
		"longitude": "-46.78870816345489",
		"postalCode": "06233-020"
	},
	{
		"name": "ALTERNATIVA",
		"localName": "Posto BR + GNV",
		"city": "Osasco",
		"address": "Av. Getúlio Vargas, 1485",
		"area": "Piratininga",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.505686434775825",
		"longitude": "-46.78870228211211",
		"postalCode": "06233-020"
	},
	{
		"name": "POSTO JARDIM OKINAWA LTDA",
		"localName": "Posto Shell",
		"city": "Paulínia",
		"address": "Av. José Álvaro Delmonde, 53",
		"area": "Jardim Okinawa",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.80028524983855",
		"longitude": "-47.131868422388465",
		"postalCode": "13141-010"
	},
	{
		"name": "BRASIL GAS PINDA",
		"localName": "GNV Brasil",
		"city": "Pindamonhangaba",
		"address": "Av. Dr. José Monteiro Machado César, 1260",
		"area": "Moreira Césara",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.92724281760043",
		"longitude": "-45.35614341809346",
		"postalCode": "12441-010"
	},
	{
		"name": "AUTO POSTO CIDADE NOVA PINDA",
		"localName": "Posto BRASIL + GNV | Cidade Nova Pinda",
		"city": "Pindamonhangaba",
		"address": "Av. Gastão Vidigal Neto, 1200",
		"area": "Cidade Nova",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.94004051983204",
		"longitude": "-45.406533458537304",
		"postalCode": "12414-020"
	},
	{
		"name": "SETE ESTRELAS COM DE DERIVADOS ",
		"localName": "Sete Estrelas",
		"city": "Pindamonhangaba",
		"address": "SP-062, 2150",
		"area": "Mombaça",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.945215921230524",
		"longitude": "-45.47843441469556",
		"postalCode": "12421-010"
	},
	{
		"name": "TIGER AUTO POSTO",
		"localName": "Posto GT Shopping",
		"city": "Piracicaba",
		"address": "Av. Limeira, 950",
		"area": "Areião",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.702851111497754",
		"longitude": "-47.64756222106296",
		"postalCode": "13414-018"
	},
	{
		"name": "AUTO POSTO DIAMANTE DE PIRACICABA LTDA",
		"localName": "Posto Amaral (GNV)",
		"city": "Piracicaba",
		"address": "Av. Comendador Luciano Guidotti, 420",
		"area": "Higienópolis",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.74117893860344",
		"longitude": "-47.64144867155488",
		"postalCode": "13417-370"
	},
	{
		"name": "COSTA PRADO",
		"localName": "Posto Ipiranga",
		"city": "Piracicaba",
		"address": "Av. Prof. Alberto Vollet Sachs, 2600",
		"area": "Morumbi",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.726099549018834",
		"longitude": "-47.626126410091054",
		"postalCode": "13417-820"
	},
	{
		"name": "PORTAL DE POÁ AUTO POSTO",
		"localName": "Posto Portal de Poá",
		"city": "Poá",
		"address": "Av. Fernando Rossi, 314",
		"area": "Vila Bandeirantes",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.51853713851866",
		"longitude": "-46.33216946872135",
		"postalCode": "08559-400"
	},
	{
		"name": "RIO CLARENSE VIA BRASIL",
		"localName": "Auto Posto Rio Clarense II com Gnv Ltda",
		"city": "Rio Claro",
		"address": "R. 23, 1308-1362",
		"area": "Jardim Claret",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.418791153853284",
		"longitude": "-47.57681267273617",
		"postalCode": "13501-645"
	},
	{
		"name": "CATURAMA  ANTIGO RFM ",
		"localName": "Posto Shell GNV RIO CLARO",
		"city": "Rio Claro",
		"address": "Av. Pres. Tancredo de Almeida Neves, 581 - 1",
		"area": "Jardim Claret",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.416467783354097",
		"longitude": "-47.57358274682386",
		"postalCode": "13503-183"
	},
	{
		"name": "AUTO POSTO BRASIL GAS LUIZ DE",
		"localName": "Posto Brasil Gas",
		"city": "Santa Bárbara d'Oeste",
		"address": "R. Wilcon Pereira",
		"area": "Parque Res. Santa Ines",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.76568220296946",
		"longitude": "-47.398017735782986",
		"postalCode": "13457-056"
	},
	{
		"name": "TENDA GNV COM DE COMB E GAS NATURAL VEICULAR LTDA",
		"localName": "Posto Tenda GNV",
		"city": "Santa Bárbara d'Oeste",
		"address": "R. da Agricultura, 874",
		"area": "Jardim Sao Fernando",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.746280936630846",
		"longitude": "-47.36454655511011",
		"postalCode": "13454-000"
	},
	{
		"name": "O ESTADÃO DE SANTO ANDRÉ ANTIGO CABEÇA BRANCA ",
		"localName": "Auto Posto Estadão - Redee Setee",
		"city": "Santo André",
		"address": "Av. dos Estados, 6700",
		"area": "Tamanduateí 8",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.652271341099787",
		"longitude": "-46.51424182699889",
		"postalCode": "09210-580"
	},
	{
		"name": "POSTO GP GNV LTDA",
		"localName": "Posto GP GNV Ltda",
		"city": "Santo André",
		"address": "Rua Giovanni Battista Pirelli, 1789",
		"area": "Tamanduateí 7",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.664411032065576",
		"longitude": "-46.49083344338528",
		"postalCode": "09111-340"
	},
	{
		"name": "CIA BRAS. DE DISTIBUICAO LOJA 2301  EXTRA",
		"localName": "Posto Shell - CIA BRASILEIRA DE DISTRIBUICAO",
		"city": "Santo André",
		"address": "R. Visc. de Taunay, 216 - Box 2",
		"area": "Centro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.652615385260084",
		"longitude": "-46.52692387019612",
		"postalCode": "09210-570"
	},
	{
		"name": "CATEQUESE AUTO POSTO",
		"localName": "Catequese Auto Posto",
		"city": "Santo André",
		"address": "R. Catequese, 1263",
		"area": "Vila Guiomar",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.65898929126061",
		"longitude": "-46.53998444236916",
		"postalCode": "09090-401"
	},
	{
		"name": "POSTO DE SERVIÇOS TRILEGAL (ANTIGO NN SANTO ANDRÉ)",
		"localName": "Posto de Serviços Trilegal",
		"city": "Santo André",
		"address": "Av. dos Estados, 7225",
		"area": "Tamanduateí 8",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.65209311695749",
		"longitude": "-46.50983645189192",
		"postalCode": "09290-340"
	},
	{
		"name": "LÍVIA AUTO POSTO",
		"localName": "Livia Auto Posto Ltda",
		"city": "Santo André",
		"address": "Av. José Antônio de Almeida Amazonas, 551",
		"area": "Vila Guiomar",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.659143543684994",
		"longitude": "-46.540086131221386",
		"postalCode": "09090-390"
	},
	{
		"name": "AUTO POSTO ARRASTÃO LTDA.",
		"localName": "Posto BR / GNV - Auto Posto Arrastão",
		"city": "Santos",
		"address": "R. Sen. Christiano Ottoni, 56",
		"area": "Valongo",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.930758885704936",
		"longitude": "-46.33701906405647",
		"postalCode": "11010-280"
	},
	{
		"name": "FORMULA INDY COMERCIO E SERVIÇOS AUTOMOTIVOS LTDA",
		"localName": "Formula Indy Com. e Serviços Automotivos (RodOil)",
		"city": "Santos",
		"address": "Av. Nossa Senhora de Fátima, 302",
		"area": "Chico de Paula",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.935131080254013",
		"longitude": "-46.36243549836696",
		"postalCode": "11085-202"
	},
	{
		"name": "POSTO BRASIL GÁS SUMARÉ",
		"localName": "Auto Posto Brasil Gás Flora de Sumaré",
		"city": "Sumaré",
		"address": "Av. Rio de Janeiro, 351",
		"area": "Jardim Nova Veneza",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.805811091238773",
		"longitude": "-47.22220144055259",
		"postalCode": "13177-010"
	},
	{
		"name": "POSTO QUALITY CASABLANCA LTDA",
		"localName": "Posto Shell - Posto Quality Casablanca",
		"city": "Suzano",
		"address": "Av. Armando Salles de Oliveira, 2185",
		"area": "Parque Santa Rosa",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.552328554040606",
		"longitude": "-46.319450622893086",
		"postalCode": "08673-000"
	},
	{
		"name": "SUZAN PETRO",
		"localName": "Posto BR",
		"city": "Suzano",
		"address": "Av. Ver. João Batista Fitipaldi, 300",
		"area": "Vila Maluf",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.532141760289147",
		"longitude": "-46.30654377565088",
		"postalCode": "08685-000"
	},
	{
		"name": "POSTO ABU-DHABI",
		"localName": "Posto Abu-Dhabi - Ipiranga",
		"city": "Suzano",
		"address": "R. Prudente de Morais, 4135",
		"area": "Vila Santana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.540762452104126",
		"longitude": "-46.27319711950065",
		"postalCode": "08613-900"
	},
	{
		"name": "QUEOPS AUTO POSTO LTDA",
		"localName": "Queop S Auto Posto Limitada",
		"city": "São Bernardo do Campo",
		"address": "Av. Pery Ronchetti, 212",
		"area": "Nova Petrópolis",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.70229205667146",
		"longitude": "-46.54205167764526",
		"postalCode": "09771-001"
	},
	{
		"name": "TÉRIOS AUTO POSTO",
		"localName": "Terios Auto Posto",
		"city": "São Bernardo do Campo",
		"address": "R. Alferes Bonilha, 531",
		"area": "Vila Magnolia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.717993983482113",
		"longitude": "-46.550215190046934",
		"postalCode": "09721-230"
	},
	{
		"name": "SCANIA LATIN AMERICA LTDA",
		"localName": "Scania Latin America Ltda",
		"city": "São Bernardo do Campo",
		"address": "Av. José Odorizzi, 151",
		"area": "Vila Euro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.712707734187315",
		"longitude": "-46.566827887772014",
		"postalCode": "09810-902"
	},
	{
		"name": "VIP AUTO POSTO LTDA",
		"localName": "Posto Com GNV",
		"city": "São Bernardo do Campo",
		"address": "Av. Senador Vergueiro, 1032-1100",
		"area": "Rudge Ramos",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.685670005245655",
		"longitude": "-46.55423806652108",
		"postalCode": "09750-230"
	},
	{
		"name": "PORTAL DE SÃO BERNARDO SERVIÇOS",
		"localName": "Auto Posto Ipiranga - Portal de São Bernardo",
		"city": "São Bernardo do Campo",
		"address": "Av. Senador Vergueiro, 2930",
		"area": "Anchieta",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.669974931408454",
		"longitude": "-46.55959589119287",
		"postalCode": "09600-004"
	},
	{
		"name": "Auto Posto Pepa 2 Ltda (ANTIGO BONAZZA)",
		"localName": "Posto Ipiranga",
		"city": "São Bernardo do Campo",
		"address": "R. Heinrich Nordoff, 61",
		"area": "Rio Grande",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.777499085222868",
		"longitude": "-46.52942683269466",
		"postalCode": "09830-180"
	},
	{
		"name": "AUTO POSTO VERGUEIRÃO",
		"localName": "Auto Posto Vergueirão",
		"city": "São Bernardo do Campo",
		"address": "Av. Senador Vergueiro, 2861",
		"area": "Rudge Ramos",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.670495361485344",
		"longitude": "-46.559923838124064",
		"postalCode": "09740-001"
	},
	{
		"name": "AUTO POSTO ECO GAS (ANTIGO ECO GÁS  SIMI)",
		"localName": "GNV",
		"city": "São Caetano do Sul",
		"address": "Av. Prosperidade, 387",
		"area": "Prosperidade",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.610996467130295",
		"longitude": "-46.5511415227188",
		"postalCode": "09550-700"
	},
	{
		"name": "ZANGÃO SERVIÇOS AUTOMOTIVOS",
		"localName": "Auto Posto BR - Zangao Serviços Automotivos",
		"city": "São Caetano do Sul",
		"address": "Av. Goiás, 3144",
		"area": "Barcelona",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.622538938327487",
		"longitude": "-46.549084428210215",
		"postalCode": "09550-051"
	},
	{
		"name": "JCN VALE",
		"localName": "Posto Petrobras - GNV / Shopping Vale",
		"city": "São José dos Campos",
		"address": "R. Jordânia, 152",
		"area": "Jardim Oswaldo Cruz",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.20205384426082",
		"longitude": "-45.88176404273287",
		"postalCode": "12216-550"
	},
	{
		"name": "AUTO POSTO BRASIL GAS DUTRA",
		"localName": "Auto Posto GNV Brasil Dutra",
		"city": "São José dos Campos",
		"address": "R. Patativa, 631",
		"area": "Vila Tatetuba",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.185175836212007",
		"longitude": "-45.853437763512254",
		"postalCode": "12220-140"
	},
	{
		"name": "AUTO POSTO MASTER VALE ANTIGO GRAN VALE",
		"localName": "Gran Vale Comércio",
		"city": "São José dos Campos",
		"address": "Av. Cidade Jardim, 764",
		"area": "Jardim Satélite",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.220375889438532",
		"longitude": "-45.885396022780775",
		"postalCode": "12231-000"
	},
	{
		"name": "POSTO DE SERVIÇOS BOLLA BRANCA",
		"localName": "Posto de Serviços Bolla Branca",
		"city": "São José dos Campos",
		"address": "Av Joao Baptista De Souza Soar, 255",
		"area": "Jardim Anhembi",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.22336197984423",
		"longitude": "-45.900830095453024",
		"postalCode": "12235-200"
	},
	{
		"name": "VITRINE",
		"localName": "Posto Petrobras",
		"city": "São José dos Campos",
		"address": "Av. Dr. Nélson d'Ávila, 1.810",
		"area": "Vila Sanches",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.202095975977603",
		"longitude": "-45.885437532434615",
		"postalCode": "12245-031"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS  PENEDO - POSTO 04",
		"localName": "Sete Estrelas",
		"city": "São José dos Campos",
		"address": "R. Penedo, 1051",
		"area": "Jardim Petropolis",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.240639322149185",
		"longitude": "-45.9169888615419",
		"postalCode": "12237-640"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS CRYLOR - POSTO 49",
		"localName": "Auto Posto Torrão",
		"city": "São José dos Campos",
		"address": "Av. Eng. Sebastião Gualberto, 1299",
		"area": "Vila Maria",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.17473836436581",
		"longitude": "-45.87701755133691",
		"postalCode": "12220-930"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS TROPICAL - POSTO 47",
		"localName": "Sete Estrelas",
		"city": "São José dos Campos",
		"address": "Av. Dr. Sebastião Henrique da Cunha Pontes, 5830",
		"area": "Palmeiras de São José",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.23412359884567",
		"longitude": "-45.914800882979804",
		"postalCode": "12237-280"
	},
	{
		"name": "BANDEIRANTE",
		"localName": "Posto Shell",
		"city": "São José dos Campos",
		"address": "Av. Dr. Nélson d'Ávila, 1650",
		"area": "Jardim Sao Dimas",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.200663315590226",
		"longitude": "-45.885819057562045",
		"postalCode": "12245-031"
	},
	{
		"name": "AUTO POSTO GASCEM LTDA.",
		"localName": "BR Mania",
		"city": "São José dos Campos",
		"address": "Av. Cidade Jardim, 920",
		"area": "Jardim Satélite",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.22083820960242",
		"longitude": "-45.88474869819251",
		"postalCode": "12231-000"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS  TEJO - POSTO 31",
		"localName": "Sete Estrelas",
		"city": "São José dos Campos",
		"address": "R. Paraibuna, 1340",
		"area": "Jardim Sao Dimas",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.203622107802662",
		"longitude": "-45.88497351002089",
		"postalCode": "12245-021"
	},
	{
		"name": "AUTO POSTO J.E LTDA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Joaquina Ramalho, 151",
		"area": "Vila Guilherme",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.514989542283473",
		"longitude": "-46.60822230828707",
		"postalCode": "02065-000"
	},
	{
		"name": "VIA BRASIL LTDA-PORTAL DA RAPOSO",
		"localName": "Posto De Combustível Via Brasil",
		"city": "São Paulo",
		"address": "R. dos Piemonteses, 841",
		"area": "Jardim do Lago",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.583156029285554",
		"longitude": "-46.783140049277996",
		"postalCode": "05550-070"
	},
	{
		"name": "AUTOPOSTO NSA I",
		"localName": "Singular",
		"city": "São Paulo",
		"address": "Av. Educador Paulo Freire, 294",
		"area": "Parque Novo Mundo",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.519171644173700",
		"longitude": "-46.56101172982740",
		"postalCode": "02187-110"
	},
	{
		"name": "PÊSSEGO COM. E SERVIÇOS COMBUSTÍVEIS E LUBRIF. LTDA",
		"localName": "Auto Posto Estação Pêssego",
		"city": "São Paulo",
		"address": "Av. Jacu-Pêssego, S/N",
		"area": "Itaquera",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.539641935244017",
		"longitude": "-46.45048657943204",
		"postalCode": "08290-730"
	},
	{
		"name": "START AUTO POSTO",
		"localName": "Shell",
		"city": "São Paulo",
		"address": "Av. do Estado, 2125",
		"area": "Vila California",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.606051623982037",
		"longitude": "-46.55145412687434",
		"postalCode": "03216-040"
	},
	{
		"name": "PORTAL DO IPIRANGA-ALESAT COMBUSTÍVEIS - DUQUE ESTADÃO ",
		"localName": "Posto BR GNV Rede Duque",
		"city": "São Paulo",
		"address": "R. Barão de Resende, 66",
		"area": "Vila Prudente",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.583320646290268",
		"longitude": "-46.5993067623962",
		"postalCode": "04210-050"
	},
	{
		"name": "WEST PARK",
		"localName": "Westpark Auto Posto",
		"city": "São Paulo",
		"address": "Rua Francisco Luiz de Souza Júnior, 145",
		"area": "Água Branca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.51516937573229",
		"longitude": "-46.68864771204785",
		"postalCode": "05037-000"
	},
	{
		"name": "POSTO DE SERVIÇOS SAGITÁRIO",
		"localName": "Posto de serviço Sagitario",
		"city": "São Paulo",
		"address": "R. Silva Teles, 1819",
		"area": "Pari",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.523748967026847",
		"longitude": "-46.60456385404172",
		"postalCode": "03026-001"
	},
	{
		"name": "POSTO DE SERVIÇOS AUTOMOTIVOS E COMERCIO DE COMBUSTÍVEIS PARIS EIRELI",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Ferreira de Oliveira, 187",
		"area": "Alto do Pari",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.524101005784253",
		"longitude": "-46.60378994934184",
		"postalCode": "03022-030"
	},
	{
		"name": "SERVIÇOS AUTOMOTIVOS PEDRODAVI LTDA.",
		"localName": "Posto Singular (GNV)",
		"city": "São Paulo",
		"address": "Rua Paulo Andrighetti, 1484",
		"area": "Alto do Pari",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52391535719088",
		"longitude": "-46.606444794729796",
		"postalCode": "03022-000"
	},
	{
		"name": "AUTO POSTO ESTADO DE SÃO PAULO (antigo converrede)",
		"localName": "Auto Posto Estado de São Paulo",
		"city": "São Paulo",
		"address": "Bandeira Branca - Av. Eng. Caetano Álvares, 370",
		"area": "Limão",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.510304895024483",
		"longitude": "-46.67037175664973",
		"postalCode": "02546-000"
	},
	{
		"name": "CAMINHO CERTO",
		"localName": "Posto Petrobras + GNV",
		"city": "São Paulo",
		"address": "Av. Manoel Domingos Pinto, 610",
		"area": "Parque Anhanguera",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.50925186856403",
		"longitude": "-46.732560109926204",
		"postalCode": "05120-000"
	},
	{
		"name": "CONFIANÇA CIA IPIRANGA",
		"localName": "Posto Confiança",
		"city": "São Paulo",
		"address": "Av. Morvan Dias de Figueiredo, 7035",
		"area": "Vila Maria",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.523340953105716",
		"longitude": "-46.56633518904971",
		"postalCode": "02170-000"
	},
	{
		"name": "TEX AUTO POSTO LTDA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Airton Pretini, 539",
		"area": "Jardim America da Penha",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52540159831714",
		"longitude": "-46.55676872176958",
		"postalCode": "03090-000"
	},
	{
		"name": "APOLO 8 ",
		"localName": "Posto Serviços Apolo 8",
		"city": "São Paulo",
		"address": "Av. Morvan Dias de Figueiredo, 2605",
		"area": "Vila Guilherme",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.522103222450735",
		"longitude": "-46.60901766815139",
		"postalCode": "02063-000"
	},
	{
		"name": "AUTO POSTO RIO VERDE",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "R. Rio Verde, 1822",
		"area": "V C Almas",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.489506931926805",
		"longitude": "-46.71151017233515",
		"postalCode": "02934-000"
	},
	{
		"name": "GOLD STAR",
		"localName": "Jet Oil - Serviços Automotivos | Ipiranga",
		"city": "São Paulo",
		"address": "R. Jacirendi, 850",
		"area": "Tatuapé",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52805174297769",
		"longitude": "-46.58082326104483",
		"postalCode": "03080-000"
	},
	{
		"name": "AUTO LIDER BANDEIRAS LTDA",
		"localName": "Auto Posto Líder Bandeiras Ltda",
		"city": "São Paulo",
		"address": "Av. Pres. Castelo Branco, 3345",
		"area": "Pte. Pequena",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.520184057911543",
		"longitude": "-46.627770594764606",
		"postalCode": "01142-300"
	},
	{
		"name": "AUTO POSTO IMPÉRIO",
		"localName": "Rede Duque",
		"city": "São Paulo",
		"address": "R. Joaquim Carlos, 1380",
		"area": "Brás",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.52441325090118",
		"longitude": "-46.608058848326465",
		"postalCode": "03019-000"
	},
	{
		"name": "POSTO DE SERVIÇO SANTA RITA DO MONTE AZUL",
		"localName": "Rede Nacional - Posto de Serviço Santa Rita do Monte Azul Ltda.",
		"city": "São Paulo",
		"address": "Estr. de Itapecerica, 1422",
		"area": "Jardim Casablanca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.64734757001869",
		"longitude": "-46.74796659082098",
		"postalCode": "05835-002"
	},
	{
		"name": "Auto Posto W.L. Ltda (antigo Centro Automotivo Palacete das Águias)",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Washington Luís, 4604",
		"area": "Vila Sao Paulo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.63858002208067",
		"longitude": "-46.67209252578906",
		"postalCode": "04626-000"
	},
	{
		"name": "AUTO POSTO PÉTALA AZUL",
		"localName": "Posto Boxter",
		"city": "São Paulo",
		"address": "R. Conselheiro Lafaiette, 255",
		"area": "Mooca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.550959984125843",
		"longitude": "-46.60868914557639",
		"postalCode": "03164-110"
	},
	{
		"name": "CENTRO DE ABASTECIMENTO DE GÁS MILENA LTDA  NIAGARA",
		"localName": "Shell",
		"city": "São Paulo",
		"address": "Av. Otaviano Alves de Lima, 3926",
		"area": "Freguesia do Ó",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.506636625859723",
		"longitude": "-46.695778827030026",
		"postalCode": "02910-025"
	},
	{
		"name": "NIPO BRASILEIRO",
		"localName": "Auto Posto Nipo Brasileiro C/Gnv",
		"city": "São Paulo",
		"address": "Avenida Marechal Tito, 3501",
		"area": "Itaim Paulista",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.494796543024783",
		"longitude": "-46.41099833032926",
		"postalCode": "08115-100"
	},
	{
		"name": "FUNCHAL",
		"localName": "Funchal Auto Posto",
		"city": "São Paulo",
		"address": "R. Funchal, 554",
		"area": "Vila Olímpia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.595449928382287",
		"longitude": "-46.69083240558919",
		"postalCode": "04551-060"
	},
	{
		"name": "TUTÓIA",
		"localName": "Posto Flor de Goiás",
		"city": "São Paulo",
		"address": "Rua Doutor Amâncio de Carvalho, 26",
		"area": "Vila Mariana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.581642261828577",
		"longitude": "-46.6495226617243",
		"postalCode": "04012-080"
	},
	{
		"name": "AUTO POSTO AEROMARTE",
		"localName": "Aero Marte",
		"city": "São Paulo",
		"address": "R. Faustino Pereira Matias, 86",
		"area": "Santana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.509217629508317",
		"longitude": "-46.627416337493784",
		"postalCode": "02012-090"
	},
	{
		"name": "POSTO DE SERVIÇOS JURUCÊ LTDA.",
		"localName": "Posto de Serviços Jurucê Ltda.",
		"city": "São Paulo",
		"address": "Av. Moreira Guimarães, 718",
		"area": "Indianópolis",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.612754641269433",
		"longitude": "-46.65767292872181",
		"postalCode": "04074-020"
	},
	{
		"name": "POSTO DE SERVIÇOS FLORIDA LTDA",
		"localName": "Posto Serviço Flórida",
		"city": "São Paulo",
		"address": "Av. Nova Cantareira, 2315",
		"area": "Tucuruvi",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.477288732156286",
		"longitude": "-46.6106504986798",
		"postalCode": "02305-003"
	},
	{
		"name": "AUTO POSTO BR SUL",
		"localName": "Posto Orion com Gnv",
		"city": "São Paulo",
		"address": "Av. Sen. Teotônio Vilela, 2631",
		"area": "Rio Bonito",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.723943826678113",
		"longitude": "-46.698825581471375",
		"postalCode": "04801-010"
	},
	{
		"name": "POSTO DE SERVIÇOS POPULAR",
		"localName": "Posto Petrobras + GNV",
		"city": "São Paulo",
		"address": "Av. Jabaquara, 1966",
		"area": "Vila da Saúde",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.6214691139423",
		"longitude": "-46.64016779368715",
		"postalCode": "04046-300"
	},
	{
		"name": "COM. VAREJISTA DE COMBUSTÍVEIS VELOZ - (ANTIGO  SERVACAR)",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Professor Luiz Ignácio Anhaia Mello, 6143",
		"area": "Vila Ema",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.591599707062187",
		"longitude": "-46.54182630347231",
		"postalCode": "03295-100"
	},
	{
		"name": "POSTO DE SERVIÇOS ROBSON EIRELLI (ANTIGO POSTO FALKE  GUAXIMIM) ",
		"localName": "Auto Posto Takata",
		"city": "São Paulo",
		"address": "Av. Pires do Rio, 1856",
		"area": "Sao Miguel",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.51311982139707",
		"longitude": "-46.443809532382325",
		"postalCode": "08041-000"
	},
	{
		"name": "AUTO POSTO EWAMARO LTDA",
		"localName": "Auto Posto Ewamaro",
		"city": "São Paulo",
		"address": "R. Antônio Macedo, 660",
		"area": "Tatuapé",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.527054871348085",
		"longitude": "-46.56441032073636",
		"postalCode": "03087-010"
	},
	{
		"name": "POSTO DE SERVIÇO IRAMAIA MORUMBI LTDA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Roque Petroni Júnior, 984",
		"area": "Cidade Monções",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.623336710166633",
		"longitude": "-46.69614175375719",
		"postalCode": "04707-000"
	},
	{
		"name": "WGÁS II RSB",
		"localName": "W Gás RB Auto Ltda.",
		"city": "São Paulo",
		"address": "Av. Sen. Teotônio Vilela, 2004",
		"area": "Rio Bonito",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.718915537481845",
		"longitude": "-46.6991183591049",
		"postalCode": "04801-000"
	},
	{
		"name": "AUTO POSTO RIO COA",
		"localName": "Auto Posto Rio Coa",
		"city": "São Paulo",
		"address": "R. Rodeiro, 102",
		"area": "Ermelino Matarazzo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.48857640872702",
		"longitude": "-46.46518549986299",
		"postalCode": "03813-110"
	},
	{
		"name": "FINLÂNDIA (ANTIGO BAND III)",
		"localName": "FINLÂNDIA (ANTIGO BAND III)",
		"city": "São Paulo",
		"address": "R. Lavariz, 3",
		"area": "Parque Colonial",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.616501234857157",
		"longitude": "-46.66476943303401",
		"postalCode": "04610-080"
	},
	{
		"name": "RODOVIAS",
		"localName": "Posto Serviços Apolo 8",
		"city": "São Paulo",
		"address": "Av. Morvan Dias de Figueiredo, 2605",
		"area": "Vila Guilherme",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52210965396281",
		"longitude": "-46.60901823857978",
		"postalCode": "02063-000"
	},
	{
		"name": "POSTO WELLCOME",
		"localName": "Rede Nacional - Posto de Serviços Welcome Ltda.",
		"city": "São Paulo",
		"address": "Av. das Belezas, 513",
		"area": "Jardim Sao Luiz",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.641931159307703",
		"longitude": "-46.74249501340273",
		"postalCode": "05731-250"
	},
	{
		"name": "AUTO POSTO LUZ DA RADIAL",
		"localName": "Posto Boxter",
		"city": "São Paulo",
		"address": "Av. Álvaro Ramos, 707",
		"area": "Quarta Parada",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.543614421093395",
		"longitude": "-46.58655764234809",
		"postalCode": "03331-000"
	},
	{
		"name": "TROPICAL GASOLINA SERVIÇOS AUTOMOTIVOS LTDA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Alcântara Machado, 360",
		"area": "Brás",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.55257785938801",
		"longitude": "-46.62159402596272",
		"postalCode": "03102-000"
	},
	{
		"name": "AUTO POSTO 2000",
		"localName": "Auto Posto Rede 1000",
		"city": "São Paulo",
		"address": "Av. Prof. Francisco Morato, 2300",
		"area": "Caxingui",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.585178811845307",
		"longitude": "-46.72110553891407",
		"postalCode": "05517-040"
	},
	{
		"name": "PORTAL DAS PERDIZES NN SUMARÉ",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Dr. Franco da Rocha, 664",
		"area": "Perdizes",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.537666400062285",
		"longitude": "-46.67417200689284",
		"postalCode": "05015-040"
	},
	{
		"name": "AUTO POSTO OITI",
		"localName": "Auto Posto Oiti",
		"city": "São Paulo",
		"address": "Av. Reg. Feijó, 1188",
		"area": "Água Rasa",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.55978199231826",
		"longitude": "-46.5676172654446",
		"postalCode": "03342-000"
	},
	{
		"name": "NOVA CACHOEIRINHA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Inajar de Souza, 3535",
		"area": "Cachoeirinha",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.478352099923285",
		"longitude": "-46.67339779977209",
		"postalCode": "02717-000"
	},
	{
		"name": "AUTO POSTO PANAMBY",
		"localName": "Shell",
		"city": "São Paulo",
		"address": "Av. Guido Caloi, 661",
		"area": "Santo Amaro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.649939727848025",
		"longitude": "-46.7263380400631",
		"postalCode": "05802-140"
	},
	{
		"name": "ARGETAX ANCHIETA",
		"localName": "ARGETAX ANCHIETA",
		"city": "São Paulo",
		"address": "Rod. Anchieta, 1368",
		"area": "Vila Moinho Velho",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.613736823538765",
		"longitude": "-46.601334469037305",
		"postalCode": "04246-001"
	},
	{
		"name": "COIMBRA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Dr. Orêncio Vidigal, 340",
		"area": "Vila Carlos de Campos",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.531183731169808",
		"longitude": "-46.540454835512385",
		"postalCode": "03640-010"
	},
	{
		"name": "Posto Serviços Automotivos Galapagos Eireli",
		"localName": "Posto Galapagos",
		"city": "São Paulo",
		"address": "Av. Queiroz Filho, 1310",
		"area": "Vila Leopoldina",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.54121533332939",
		"longitude": "-46.730132978256165",
		"postalCode": "05319-000"
	},
	{
		"name": "DUQUE BANDEIRANTES",
		"localName": "Rede Duque",
		"city": "São Paulo",
		"address": "R. Conceição de Monte Alegre, 1680",
		"area": "Brooklin",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.60180909130788",
		"longitude": "-46.68278387881329",
		"postalCode": "04558-040"
	},
	{
		"name": "SUPER POSTO FLOR DO CARRÃO",
		"localName": "Posto Ale + GNV",
		"city": "São Paulo",
		"address": "Av. Conselheiro Carrão, 2673",
		"area": "Vila Carrao",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.54916218678065",
		"longitude": "-46.535904797521106",
		"postalCode": "03403-002"
	},
	{
		"name": "ANA NERY POSTO XARANGA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Dona Ana Neri, 1156",
		"area": "Cambuci",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.5639908791377",
		"longitude": "-46.61808936521103",
		"postalCode": "01522-000"
	},
	{
		"name": "WALBRAX 1 AUTO CENTER",
		"localName": "Auto Posto Center Walbrax",
		"city": "São Paulo",
		"address": "Av. Pires do Rio, 458",
		"area": "Vila Americana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.500424821456242",
		"longitude": "-46.44262151402483",
		"postalCode": "08020-350"
	},
	{
		"name": "POSTO VM RODRIGUES  (ANTIGO RONEY PERES DE BRITO )",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "R. Estevão Ribeiro Garcia, 30",
		"area": "Jardim Virginia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.4961372436751",
		"longitude": "-46.39174679124063",
		"postalCode": "08120-590"
	},
	{
		"name": "AUTO POSTO IMBO",
		"localName": "Posto ALE + GNV | IMBO",
		"city": "São Paulo",
		"address": "Av. Reg. Feijó, 752",
		"area": "Vila Reg. Feijó",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.55800549803859",
		"longitude": "-46.5712820415227",
		"postalCode": "03342-000"
	},
	{
		"name": "MUNDO NOVO",
		"localName": "Posto de Serviço Mundo Novo",
		"city": "São Paulo",
		"address": "Av. Morvan Dias de Figueiredo, 6779",
		"area": "Parque Novo Mundo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.524570492524443",
		"longitude": "-46.56856761815401",
		"postalCode": "02170-000"
	},
	{
		"name": "AUTOPOSTO TROPICAL",
		"localName": "",
		"city": "São Paulo",
		"address": "Avenida Educador Paulo Freire, 510",
		"area": "Parque Novo Mundo",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.51804191922230",
		"longitude": "-46.56089944635770",
		"postalCode": "02187-110"
	},
	{
		"name": "BATALHA",
		"localName": "Posto Shell + GNV | Batalha",
		"city": "São Paulo",
		"address": "R. Azem Abdalla Azem, 2600",
		"area": "Butantã",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.581026411364235",
		"longitude": "-46.73080226597537",
		"postalCode": "05593-090"
	},
	{
		"name": "ML20 IMIGRANTES - Antigo GAVIÃO DA IMIGRANTES",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Miguel Estefano, 1663",
		"area": "Vila da Saúde",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.624903134264983",
		"longitude": "-46.62914965712061",
		"postalCode": "04301-001"
	},
	{
		"name": "POSTO DA PONTE",
		"localName": "Posto Shell - Rede Papa GNV",
		"city": "São Paulo",
		"address": "Av. Nossa Senhora do Ó, 1693",
		"area": "Nossa Senhora do Ó",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.505178891924775",
		"longitude": "-46.689301097899985",
		"postalCode": "02715-000"
	},
	{
		"name": "SILGUEKRON",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Guarapiranga, 1791",
		"area": "Parque Alves de Lima",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.667669167325315",
		"longitude": "-46.72912865672378",
		"postalCode": "04902-015"
	},
	{
		"name": "Argetax Posto Dallas",
		"localName": "Auto Posto Pascua Marques LTDA",
		"city": "São Paulo",
		"address": "Eliseu de Almeida, 2814",
		"area": "Jardim Peri Peri",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.58897879343055",
		"longitude": "-46.733799374953044",
		"postalCode": "05537-040"
	},
	{
		"name": "AUTO POSTO DUQUE ZAGOTTIS",
		"localName": "Rede Duque",
		"city": "São Paulo",
		"address": "Av. Eng. Alberto de Zagottis, 567",
		"area": "Jurubatuba",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.668937363704114",
		"longitude": "-46.6959118039019",
		"postalCode": "04676-000"
	},
	{
		"name": "ARCO POSTO DE SERVIÇOS LTDA",
		"localName": "ARCO POSTO DE SERVIÇOS LTDA",
		"city": "São Paulo",
		"address": "Av. Interlagos, 635",
		"area": "Santo Amaro",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.659834804743802",
		"longitude": "-46.679181201099794",
		"postalCode": "04661-000"
	},
	{
		"name": "P. S .UMARIZAL",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Est Do Campo Limpo, 5746",
		"area": "Pirajussara 6",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.61795891372468",
		"longitude": "-46.75824383173165",
		"postalCode": "05787-000"
	},
	{
		"name": "POSTO FÁBIO MOTA MARQUES LTDA (ANTIGO CHAPARRAL VIA VENETO )",
		"localName": "Posto Extra",
		"city": "São Paulo",
		"address": "Av. Condessa Elizabeth de Robiano, 5500",
		"area": "Penha de França",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.520676210770755",
		"longitude": "-46.555075544978486",
		"postalCode": "03704-000"
	},
	{
		"name": "AUTO POSTO SÃO FRANCISCO LTDA      ",
		"localName": "AUTO POSTO SÃO FRANCISCO",
		"city": "São Paulo",
		"address": "Av. Corifeu de Azevedo Marques, 5842",
		"area": "Vila Lageado",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.553075868535192",
		"longitude": "-46.75675155289691",
		"postalCode": "05340-002"
	},
	{
		"name": "AUTO POSTO JAGUAR DO MANDAQUI LTDA.",
		"localName": "AUTO POSTO JAGUAR DO MANDAQUI LTDA.",
		"city": "São Paulo",
		"address": "R. Voluntários da Pátria, 4380",
		"area": "Santana",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.48307010166221",
		"longitude": "-46.631386753299324",
		"postalCode": "02402-600"
	},
	{
		"name": "AUTO POSTO 710",
		"localName": "Posto Ipiranga GNV",
		"city": "São Paulo",
		"address": "R. Clodomiro Amazonas, 710",
		"area": "Vila Olímpia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.590473410764446",
		"longitude": "-46.67832594502327",
		"postalCode": "04537-001"
	},
	{
		"name": "INTERLAKEN",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Interlagos, 2981",
		"area": "Interlagos",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.677801254803747",
		"longitude": "-46.68270777832397",
		"postalCode": "04661-000"
	},
	{
		"name": "LIDER SANTOS DUMONT",
		"localName": "Posto Petrobras",
		"city": "São Paulo",
		"address": "Av. do Estado, 1036",
		"area": "Mercado",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.524430074186668",
		"longitude": "-46.63164327847267",
		"postalCode": "01107-000"
	},
	{
		"name": "AUTO POSTO CRISTAIS DO TATUAPÉ LTDA  ",
		"localName": "Posto Setee - Auto Posto Cristais do Tatuapé",
		"city": "São Paulo",
		"address": "R. Filipe Camarão, 461",
		"area": "Tatuapé",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.539162700135176",
		"longitude": "-46.578877472053",
		"postalCode": "Tatuapé"
	},
	{
		"name": "AUTO POSTO ANGRA DOS REIS LTDA",
		"localName": "Posto Boxter",
		"city": "São Paulo",
		"address": "R. Catumbi, 1400",
		"area": "Catumbi",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.525700113547945",
		"longitude": "-46.601015621477146",
		"postalCode": "03021-000"
	},
	{
		"name": "JUREMA  SM ESPAÇO AUTOMOTIVE",
		"localName": "Posto BR - Via Marte",
		"city": "São Paulo",
		"address": "Av. Dr. Assis Ribeiro, 536",
		"area": "Cangaiba",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.509615003282757",
		"longitude": "-46.545887104957856",
		"postalCode": "03717-000"
	},
	{
		"name": "JARDIM DA SAÚDE",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. do Cursino, 2154",
		"area": "Bosque da Saúde",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.620528188968326",
		"longitude": "-46.62105455463519",
		"postalCode": "04132-001"
	},
	{
		"name": "SANTA CECÍLIA CIA IPIRANGA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Dr. Frederico Steidel, 58",
		"area": "Santa Cecilia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.541021566031755",
		"longitude": "-46.646991699504696",
		"postalCode": "01225-030"
	},
	{
		"name": "MARACANÃ ",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Estr. de Itapecerica, 1397",
		"area": "Vila das Belezas",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.647522715102077",
		"longitude": "-46.747038295967606",
		"postalCode": "05849-440"
	},
	{
		"name": "POSTO DE SERVICOS ADVANTGARDE LTDA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Eng. Caetano Álvares, 4605",
		"area": "Vila Romero",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.486507236439607",
		"longitude": "-46.64378810947139",
		"postalCode": "02413-000"
	},
	{
		"name": "CAMBUCI Auto Posto",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Largo do Cambuci, 172",
		"area": "Cambuci",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.56363618553201",
		"longitude": "-46.62073239148189",
		"postalCode": "01523-010"
	},
	{
		"name": "AUTO POSTO DUQUE SALIM",
		"localName": "Rede Duque",
		"city": "São Paulo",
		"address": "Av. Salim Farah Maluf, 3400",
		"area": "Vila Bertioga",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.559737027753457",
		"longitude": "-46.57735901744984",
		"postalCode": "03076-010"
	},
	{
		"name": "AUTO POSTO EDU LTDA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Luís Stamatis, 240",
		"area": "Vila Constança",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.46804318812625",
		"longitude": "-46.58282259393351",
		"postalCode": "02260-000"
	},
	{
		"name": "AUTO POSTO NOSSA SENHORA DA PENHA",
		"localName": "Auto Posto Nossa Senhora da Penha - Posto GNV",
		"city": "São Paulo",
		"address": "Av. Amador Bueno da Veiga, 20",
		"area": "Penha de França",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52332361433324",
		"longitude": "-46.544509666383206",
		"postalCode": "03637-010"
	},
	{
		"name": "OURO 22",
		"localName": "Posto Petrobras",
		"city": "São Paulo",
		"address": "R. Dr. Jesuíno Maciel, 789",
		"area": "Campo Belo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.618582218107004",
		"longitude": "-46.67272024725636",
		"postalCode": "04615-001"
	},
	{
		"name": "MS2 AUTO POSTO LTDA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Cristalino Rolim de Freitas, 96",
		"area": "Jurubatuba",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.664023612434594",
		"longitude": "-46.707071703679915",
		"postalCode": "04696-310"
	},
	{
		"name": "PAPA 5 BRAZ LEME",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Braz Leme, 164",
		"area": "Casa Verde",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.513490828426608",
		"longitude": "-46.653162178185006",
		"postalCode": "02511-000"
	},
	{
		"name": "ARGETAX CHICO PONTES",
		"localName": "posto Agtax",
		"city": "São Paulo",
		"address": "R. Chico Pontes, 1292",
		"area": "Vila Guilherme",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.508874999924394",
		"longitude": "-46.600339065825914",
		"postalCode": "02067-002"
	},
	{
		"name": "ORION AUTO POSTO LTDA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Av. Jaguaré, 740",
		"area": "Jaguaré",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.553854614762578",
		"longitude": "-46.74271248716598",
		"postalCode": "05346-000"
	},
	{
		"name": "PARANÁ",
		"localName": "Posto Petrobras",
		"city": "São Paulo",
		"address": "R. Alvarenga, 1041",
		"area": "Butantã",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.57279364082379",
		"longitude": "-46.7115755507965",
		"postalCode": "05509-001"
	},
	{
		"name": "POSTO SENA PARK",
		"localName": "Posto Shell + GNV | Sena Park ( 24 Horas )",
		"city": "São Paulo",
		"address": "R. Sena Madureira, 1490",
		"area": "Vila ClementinoA",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.59239476068808",
		"longitude": "-46.64798357663886",
		"postalCode": "04021-001"
	},
	{
		"name": "SANTA HELENA (ANTIGO CALUANA)",
		"localName": "CENTRO AUTOMOTIVO SANTA HELENA LTDA",
		"city": "São Paulo",
		"address": "Av. Corifeu de Azevedo Marques, 44",
		"area": "Butantã",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.569185861490862",
		"longitude": "-46.71574441169952",
		"postalCode": "05582-000"
	},
	{
		"name": "AUTO POSTO ENGENHEIRO 1200 LTDA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Tangerinas, 353 - Limão",
		"area": "Limão",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.503887801231713",
		"longitude": "-46.6674544982197",
		"postalCode": "02521-080"
	},
	{
		"name": "BRAZATLAS",
		"localName": "BR Mania Conveniência",
		"city": "São Paulo",
		"address": "Rua do Gasômetro, 570",
		"area": "Brás",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.543954985283765",
		"longitude": "-46.62060050266778",
		"postalCode": "03004-000"
	},
	{
		"name": "LAGOS ANDINOS CIA IPIRANGA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Miguel Estefano, 2309",
		"area": "Parque do Estado",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.633413998756687",
		"longitude": "-46.627208589154534",
		"postalCode": "04301-012"
	},
	{
		"name": "GLAUSSEA",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "Rua Tamuatá, 20",
		"area": "São João Climaco",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.62074383648754",
		"longitude": "-46.59219561807013",
		"postalCode": "04255-140"
	},
	{
		"name": "AUTO POSTO BERTIOGA LTDA",
		"localName": "Auto Posto Bertioga LTDA - Posto Petrobras",
		"city": "São Paulo",
		"address": "R. do Acre, 54",
		"area": "Vila Bertioga",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.560745017711078",
		"longitude": "-46.58481308013427",
		"postalCode": "03181-100"
	},
	{
		"name": "AUTO POSTO RELIX LTDA",
		"localName": "Rede 28 - Auto Posto Relix Ltda.",
		"city": "São Paulo",
		"address": "Av. João Dias, 3107",
		"area": "Jardim Santo Antonio",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.643269743797998",
		"longitude": "-46.73311752836561",
		"postalCode": "05723-400"
	},
	{
		"name": "POSTO BG GNV-POSTO BELA VISTA",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. João Dias, 1978",
		"area": "Várzea de Baixo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.64469504009248",
		"longitude": "-46.72121887730925",
		"postalCode": "47240-003"
	},
	{
		"name": "CHOW GAS AUTO POSTO LTDA.",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Prof. Cardozo de Melo Neto, 1516",
		"area": "Vila Guacuri",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.69702619083879",
		"longitude": "-46.63307092703629",
		"postalCode": "04474-180"
	},
	{
		"name": "PIU PIU AUTO CENTER LTDA.",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "R. Antônio de Barros, 2181",
		"area": "Tatuapé",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.5490021162975",
		"longitude": "-46.55467066707693",
		"postalCode": "03401-001"
	},
	{
		"name": "AUTO POSTO ARCO DO TRIUNFO",
		"localName": "Posto Petrobras",
		"city": "São Paulo",
		"address": "R. Dr. Moisés Kahan, 501",
		"area": "Barra Funda",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.519303350901847",
		"longitude": "-46.6738695793442",
		"postalCode": "01139-040"
	},
	{
		"name": "PLAYGAS",
		"localName": "Posto Ipiranga",
		"city": "São Paulo",
		"address": "Av. Marquês de São Vicente, 999",
		"area": "Barra Funda",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.52066431772514",
		"longitude": "-46.6705332616695",
		"postalCode": "01139-003"
	},
	{
		"name": "ALICEMAR",
		"localName": "Posto Petrobras Av Marechal",
		"city": "São Paulo",
		"address": "Avenida Marechal Tito, 6034",
		"area": "Jardim Miragaia",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.492714420634243",
		"longitude": "-46.38818621544115",
		"postalCode": "08115-000"
	},
	{
		"name": "POSTO PHENIX COM. VAR. DE COMBUSTIVEIS (ANTIGO NAUTILUS-RAIZEN)",
		"localName": "Posto Shell",
		"city": "São Paulo",
		"address": "R. Silva Bueno, 754",
		"area": "Ipiranga",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.584980661001836",
		"longitude": "-46.60205759092907",
		"postalCode": "04208-000"
	},
	{
		"name": "QUALITÁ",
		"localName": "Posto Petrobras",
		"city": "São Paulo",
		"address": "R. Sarapuí, 277",
		"area": "Mooca",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.565753620810415",
		"longitude": "-46.601065993301305",
		"postalCode": "03123-010"
	},
	{
		"name": "Auto Posto Start Aricanduva",
		"localName": "Auto Posto Start Aricanduva",
		"city": "São Paulo",
		"address": "Av. Aricanduva, 2425",
		"area": "Vila Matilde",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.54429248982374",
		"longitude": "-46.52943235532828",
		"postalCode": "03527-000"
	},
	{
		"name": "AUTO POSTO PACIFIC I",
		"localName": "AUTO POSTO PACIFIC I",
		"city": "São Paulo",
		"address": "Rua Soldado Francisco Franco 48",
		"area": "Parque Novo Mundo",
		"state": "SP",
		"highFlow": false,
		"latitude": "",
		"longitude": "",
		"postalCode": ""
	},
	{
		"name": "Itamarathy Auto posto e acessórios Ltda – Itamarathy  V ",
		"localName": "Itamarathy Auto posto e acessórios Ltda – Itamarathy  V ",
		"city": "São Paulo",
		"address": "AV FRANCISCO MARENGO, 95",
		"area": "Vila Maluf",
		"state": "SP",
		"highFlow": false,
		"latitude": "",
		"longitude": "",
		"postalCode": ""
	},
	{
		"name": "AUTO POSTO PARQUE PIQUERI LTDA",
		"localName": "AUTO POSTO PARQUE PIQUERI LTDA",
		"city": "São Paulo",
		"address": "RUA TUIUTI, SN",
		"area": "Tatuapé",
		"state": "SP",
		"highFlow": false,
		"latitude": "",
		"longitude": "",
		"postalCode": ""
	},
	{
		"name": "BR  POSTO 800 MILHAS",
		"localName": "Posto BR 800 Milhas (GNV)",
		"city": "São Vicente",
		"address": "Av. Presidente Wilson, 759",
		"area": "Itararé",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.968558407417447",
		"longitude": "-46.376102368510736",
		"postalCode": "11320-001"
	},
	{
		"name": "AUTO POSTO TUBARÃO - Antigo ROTA 116 ",
		"localName": "BR GNV - AUTO POSTO ROTA 116",
		"city": "Taboão da Serra",
		"address": "Rod. Régis Bittencourt, 1530 - 5",
		"area": "Jardim Tres Marias",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.607663922435158",
		"longitude": "-46.76806782522275",
		"postalCode": "06750-000"
	},
	{
		"name": "SETE ESTRELAS COM. E DER. DE PETR.  BANDEIRANTES - FILIAL 10",
		"localName": "Sete Estrelas",
		"city": "Taubaté",
		"address": "Av. Bandeirantes, 5236",
		"area": "Independência",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.043503998846266",
		"longitude": "-45.582022658500975",
		"postalCode": "12061-590"
	},
	{
		"name": "REDE DE POSTOS SETE ESTRELAS  TAUBATÉ - FILAIL 07",
		"localName": "Sete Estrelas",
		"city": "Taubaté",
		"address": "Av. Charles Schnneider, 1775",
		"area": "Parque Sr. do Bonfim",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.025262848819764",
		"longitude": "-45.583109971301",
		"postalCode": "12090-600"
	},
	{
		"name": "CENTRO AUTOMOTIVO SETE ESTRELAS LTDA – Posto 67 ( POSTO DE GÁS NATURAL TAUBATÉ)",
		"localName": "Sete Estrelas",
		"city": "Taubaté",
		"address": "Av. Dom Pedro I, 5391",
		"area": "São Gonçalo",
		"state": "SP",
		"highFlow": false,
		"latitude": "-23.044726204437545",
		"longitude": "-45.58131750933398",
		"postalCode": "12091-000"
	},
	{
		"name": "AUTO POSTO DUTRA SUL LTDA",
		"localName": "Ruff",
		"city": "Taubaté",
		"address": "Rod. Pres. Dutra, 2067",
		"area": "Jardim Gurilândia",
		"state": "SP",
		"highFlow": true,
		"latitude": "-23.012592478840865",
		"longitude": "-45.52438154573118",
		"postalCode": "12071-500"
	},
	{
		"name": "AUTO POSTO SACI LTDA.",
		"localName": "Saci",
		"city": "Valinhos",
		"address": "LOC, SP-330, SN - KM 84",
		"area": "Macuco",
		"state": "SP",
		"highFlow": true,
		"latitude": "-22.997177673096264",
		"longitude": "-47.03422874257415",
		"postalCode": "13273-609"
	},
	{
		"name": "SUPER TOF",
		"localName": "Super Posto Tof",
		"city": "Valinhos",
		"address": "Av. dos Esportes, 1092",
		"area": "Jardim Primavera",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.976850046562653",
		"longitude": "-47.000391288900296",
		"postalCode": "13270-210"
	},
	{
		"name": "FERRAZ E FERRAZ",
		"localName": "Posto Ferraz & Ferraz",
		"city": "Valinhos",
		"address": "R. Dr. Marino Costa Terra, 2451",
		"area": "Vila Pagano",
		"state": "SP",
		"highFlow": false,
		"latitude": "-22.990285275786253",
		"longitude": "-47.00376605150487",
		"postalCode": "13277-200"
	}
]