import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { GoogleMapsModule } from '@angular/google-maps';
import { GoogleMapsService } from '@shared/services';

export interface IMapsData {
  name?: string;
  localName?: string;
  address?: string;
  area?: string;
  city?: string;
  state?: string;
  latitude?: string;
  longitude?: string;
  highFlow?: boolean;
  postalCode?: string;
  visible?: boolean;
}

@Component({
  selector: 'qsc-google-maps',
  standalone: true,
  imports: [CommonModule, GoogleMapsModule],
  providers: [TitleCasePipe],
  encapsulation: ViewEncapsulation.None,
  templateUrl: './google-maps.component.html',
  styleUrls: ['./google-maps.component.scss'],
})
export class GoogleMapsComponent implements OnInit, OnChanges {
  @Input() locations: IMapsData[] = [];

  constructor(
    private readonly googleMapsService: GoogleMapsService,
    private readonly titleCasePipe: TitleCasePipe
  ) {}

  map?: google.maps.Map;
  markers: google.maps.marker.AdvancedMarkerElement[] = [];

  async ngOnInit(): Promise<void> {
    await this.initMap().then(async () => {
      if (this.locations.length > 0) {
        await this.updateMarkers();
        this.googleMapsService.centerMap(this.markers, this.map);
      }
    });
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (!this.map) return;

    if (changes['locations']) {
      await this.updateMarkers();

      setTimeout(async () => {
        this.googleMapsService.centerMap(this.markers, this.map);
      }, 200);
    }
  }

  private async initMap(): Promise<void> {
    this.map = await this.googleMapsService.initMap('map');
  }

  private async updateMarkers(): Promise<void> {
    this.markers.forEach((marker) => (marker.map = null));
    this.markers = [];

    this.locations = this.locations.filter(
      (location) => location.latitude && location.longitude && location.visible
    );

    if (this.locations.length === 0) return;

    this.markers = await Promise.all(
      this.locations.map(async (location) => {
        this.transform(location);
        return await this.googleMapsService.createMarker(location, this.map);
      })
    );
  }

  public transform(location: IMapsData): void {
    this.titleCasePipe.transform(location.name);
    this.titleCasePipe.transform(location.address);
    this.titleCasePipe.transform(location.area);
    this.titleCasePipe.transform(location.city);
  }
}
