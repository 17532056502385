{
  "sitename": "Comgás",
  "hostname": "https://www.querocomgas.com.br",
  "schemas": {
    "Website": {
      "@context": "https://schema.org",
      "@type": "WebSite",
      "url": "https://www.querocomgas.com.br/",
      "potentialAction": {
        "@type": "SearchAction",
        "target": "https://www.querocomgas.com.br/search?q={search_term_string}",
        "query-input": "required name=search_term_string"
      }
    }
  },
  "pages": {
    "home": {
      "title": "Segurança e praticidade Garantida com Gás Natural Encanado",
      "description": "Garanta a segurança de sua casa ou empresa com o gás encanado da Comgás. Priorizamos sua tranquilidade!  Contrate agora e seja cliente Comgás.",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.querocomgas.com.br/"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Onde o gás natural pode ser utilizado?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O gás natural oferece aos consumidores várias possibilidades de uso, nos segmentos residencial, comercial, industrial e até mesmo como combustível veicular. Você pode utilizar o gás natural: Em fogões, fornos, churrasqueiras, lareiras Em aquecedores de água para chuveiros e piscinas de casas, escolas, academias e hotéis Em chapas, fornos, fritadeiras, fornos de pizza, churrasqueiras, lava-louças, lava-roupas, secadoras e outros equipamentos comerciais No abastecimento de geradores elétricos para indústrias, empresas, condomínios e residências Com equipamentos de climatização (calor/frio), eletricidade e força motriz em indústrias E, por fim, como combustível veicular em ônibus e carros, substituindo o óleo diesel, a gasolina e o etanol.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Qual é a diferença entre o gás encanado e o gás de botijão?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O gás natural encanado chega até você por tubulação subterrânea, ao contrário do gás de botijão, que precisa ser estocado em casa e ocupa espaço. É mais seguro, por ser mais leve que o ar, ele se dissipa rapidamente e diminui o risco de explosões em caso de vazamento. Além disso, seu fornecimento é contínuo* 24 horas por dia. *Fornecimento contínuo, exceto em hipóteses ressalvadas na regulação vigente. O gás natural encanado também não solta fuligem, é mais sustentável (emite menos poluentes) e não é tóxico.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "E as vantagens financeiras, quais são?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Com os aumentos de preço do gás de botijão, fica mais difícil programar seus gastos e manter a economia, não é? Com o gás natural encanado é diferente! Você paga apenas o que consumir e os valores são regulados pela Arsesp (Agência Reguladora de Saneamento e Energia do Estado de São Paulo), evitando surpresas na sua conta de gás.</p>"
              }
            }
          ]
        }
      }
    },
    "residential": {
      "title": "Praticidade com Gás Residencial Encanado",
      "description": "Descubra as vantagens da instalação de gás encanado residencial, proporcionando praticidade e conforto contínuos para sua casa. Seja cliente Comgás!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Residências",
              "item": "https://www.querocomgas.com.br/residencias"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Como saber se tem gás encanado na minha rua?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Para saber se há gás encanado disponível em sua rua, entre em contato com a Comgás, clique aqui. A Comgás oferece soluções personalizadas para diversos segmentos e pode fornecer gás natural encanado para sua casa ou empresa. Basta selecionar seu segmento para obter mais informações. O gás natural encanado é uma opção segura, prática e sustentável, que traz muitos benefícios para quem busca praticidade e eficiência.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como funciona o gás encanado em casa?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O funcionamento do gás encanado em casa é simples: o gás natural chega por meio de tubulações subterrâneas conectadas à rede da Comgás e é controlado por um medidor instalado na entrada da residência. A partir daí, é distribuído para os pontos de consumo, como fogão, aquecedor e chuveiro, por meio de uma atração interna. É importante que a instalação do gás encanado seja feita por um profissional qualificado, seguindo as normas de segurança da Comgás.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quais são as vantagens de usar gás encanado em vez de botijões de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O gás natural encanado proporciona benefícios significativos para residências,comércios e indústrias, incluindo a conveniência de eliminar a necessidade de troca de botijões,garantindo segurança, conforto e um fornecimento contínuo*.</p>"
              }
            }
          ]
        }
      }
    },
    "buildings": {
      "title": "Instalação de Gás Encanado Segura em Condomínios",
      "description": "Explore os benefícios do gás encanado em seu condomínio: segurança e economia. Tenha água quente em seu condomínio com a Comgás.",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Prédios",
              "item": "https://www.querocomgas.com.br/predios"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Qual o tipo de gás usado em apartamento?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O tipo de gás usado em apartamentos é o gás natural encanado, que chega até o prédio por meio de tubulações subterrâneas conectadas à rede da Comgás. De acordo com as normas de segurança da ABNT e do Inmetro.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como o gás natural é transformado em energia?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O gás natural é transformado em energia por meio de combustão, que ocorre nos aparelhos que utilizam o gás, como fogões a gás, aquecedores e chuveiros. A queima do gás natural libera calor, que é utilizado para cozinhar alimentos, aquecer água, entre outras finalidades.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quais são as vantagens de instalar gás encanado no condomínio? E onde posso usar?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>As vantagens de instalar gás encanado no condomínio são muitas: segurança,praticidade, continuidade sem interrupção, sustentabilidade e não toxicidade.O gás encanado pode ser utilizado em diversos pontos do condomínio, como fogões, aquecedores,churrasqueiras, piscinas, saunas, entre outros.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como posso monitorar o consumo de gás do condomínio?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Para monitoramento, utilizamos um medidor de gás para verificar o consumo total do condomínio ou da unidade.É importante ressaltar que a conta de gás pode ser coletiva ou individual para condomínios,dependendo da escolha dos moradores e das normas adotadas pelo condomínio.Na Comgás, você paga só depois de consumir! A fatura chega de 30 a 45 dias após o consumo e você ainda pode programar o pagamento por débito automático para facilitar seu dia a dia.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quanto tempo leva para instalar o gás encanado em um condomínio?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A instalação do gás encanado em um condomínio não tem um tempo exato, pois depende da necessidade e complexidade de cada caso.A adequação seguirá as normas NBR 13.103 e NBR 15.526, a COMGÁS oferece serviços de consultoria técnica para condomínios para auxiliar no processo.Se houver contrato de GLP, é necessário enviar uma cópia ao jurídico da COMGÁS para análise de multas e responsabilidades de pagamento.Ao iniciar a obra, o condomínio deve solicitar a rescisão contratual, podendo ocasionar o corte do gás encanado fornecido pelo antigo fornecedor, mas a COMGÁS garante o fornecimento de gás natura durante o processo.</p>"
              }
            }
          ]
        }
      }
    },
    "commerce": {
      "title": "Potencialize Seu Negócio com Gás Encanado",
      "description": "Alavanque seu negócio com o gás encanado da Comgás. Aumente a eficiência de seu comércio com nosso gás natural. Seja cliente Comgás!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Negócios",
              "item": "https://www.querocomgas.com.br/negocios"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Quais são as vantagens de usar gás natural encanado em meu estabelecimento comercial?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A instalação de gás natural encanado oferece diversas vantagens, ele é uma fonte de energia mais limpa e segura do que outras opções, como o GLP (gás liquefeito de petróleo).O gás natural é mais econômico e eficiente, o que pode reduzir significativamente os custos operacionais do seu negócio e seu fornecimento é contínuo*.Outra vantagem é a praticidade: com o gás encanado, você não precisa se preocupar em trocar botijões ou armazenar combustível em seu estabelecimento.*Fornecimento contínuo, exceto em hipóteses ressalvadas na regulação vigente. </p>"
              }
            },
            {
              "@type": "Question",
              "name": "É necessário algum tipo de manutenção regular?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Não, o gás natural não exige manutenção frequente, pois não gera fuligem nem resíduos que possam obstruir as tubulações .No entanto, é importante realizar inspeções periódicas para garantir a segurança e o bom funcionamento do sistema.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quanto tempo leva para instalar o gás natural encanado em um estabelecimento comercial?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O tempo de instalação do gás natural encanado pode variar de acordo com o tamanho e a complexidade do estabelecimento comercial.Em geral, o processo envolve uma avaliação da rede de distribuição local, a instalação das tubulações de gás e dos equipamentos necessários, além da realização de testes de segurança. </p>"
              }
            },
            {
              "@type": "Question",
              "name": "O fornecedor oferece assistência em caso de emergências relacionadas ao gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, a Comgás oferece o gás natural encanado e a assistência técnica especializada em caso de emergências relacionadas ao gás.Nossos canais de atendimento funcionam 24 horas para atender às demandas dos clientes, é importante lembrar que, em caso de vazamentos ou outros problemas relacionados ao gás, é fundamental seguir as orientações de segurança e acionar imediatamente a equipe responsável pelo atendimento.</p>"
              }
            }
          ]
        }
      }
    },
    "industries": {
      "title": "Alto Rendimento Industrial com Gás Natural",
      "description": "Eleve o desempenho de sua indústria com o gás natural da Comgás. Aumente a produtividade e economize com nosso gás industrial. Contrate agora!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Industria",
              "item": "https://www.querocomgas.com.br/industrias"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "O que é ICMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O ICMS, Imposto sobre Circulação de Mercadorias e Prestação de Serviços, é um imposto estadual que incide sobre a circulação de mercadorias em âmbito interestadual, intermunicipal ou exterior. Cada estado tem autonomia para estabelecer suas próprias regras de cobrança desse imposto, seguindo os requisitos mínimos determinados pela Constituição Federal e pelo Código Tributário Nacional.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quais são as vantagens de mudar para o seu fornecimento de gás encanado/gás natural?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Mudar para o fornecimento de gás encanado/gás natural oferece diversas vantagens, como a redução de custos em relação ao GLP, maior segurança e praticidade no uso do gás,além de ser uma fonte de energia mais limpa e não gera fuligem.O gás natural é altamente eficiente e sustentável, contribuindo para a preservação do meio ambiente e oferecendo soluções inovadoras para diversos setores industriais.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Há alguma interrupção no fornecimento de gás durante o processo de migração?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Durante o processo de migração para o fornecimento de gás natural encanado, a COMGÁS realiza uma avaliação técnica antes de formalizar a migração, garantindo que não haja interrupção no fornecimento de gás e que a indústria continue suas atividades normalmente.Nossa equipe técnica especializada COMGÁS planeja todo o processo de forma segura e eficiente,evitando qualquer tipo de interrupção no abastecimento de gás.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Há alguma restrição geográfica ou limitação para o fornecimento de gás à minha indústria?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A COMGÁS possui uma ampla rede de distribuição de gás encanado/gás natural,atendendo diversas regiões do estado de São Paulo.Para saber se sua indústria está dentro da área de cobertura, é necessário entrar em contato com a COMGÁS e verificar a disponibilidade do serviço. </p>"
              }
            }
          ]
        }
      }
    },
    "vehicles": {
      "title": "GNV - A Escolha Ideal para Veículos Leves e Pesados",
      "description": "O GNV da Comgás é a escolha perfeita para veículos pesados e leves. Poupe dinheiro e contribua para um futuro mais sustentável. Seja cliente Comgás!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Gás Natural Veícular",
              "item": "https://www.querocomgas.com.br/veiculos"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "O GNV é seguro para uso em veículos e frotas?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, o gás natural veicular é uma opção segura para uso em veículos e frotas, os tanques de GNV são projetados para suportar altas pressões e passam por rigorosos testes de segurança antes de serem instalados em veículos.Além disso, o gás natural é mais leve que o ar e se dissipa rapidamente em caso de vazamento.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Qual é a economia média de combustível para carros que usam GNV?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A economia média de combustível para carros que utilizam gás natural veicular pode variar dependendo do modelo do veículo e do tipo de condução.Em geral, os motoristas podem esperar uma economia entre 30% e 50% em comparação com o uso de gasolina ou diesel, exemplificado na matéria do Exame de 2023, GNV é até 60% mais econômico que a gasolina e o etanol.Isso pode representar uma grande economia para taxistas, frotistas, motoristas de aplicativos,locadoras e qualquer pessoa que está procurando uma forma de diminuir gastos.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quanto tempo leva para instalar o gás natural encanado em um estabelecimento comercial?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O tempo necessário para instalar o gás natural encanado em um posto de gasolina pode variar dependendo do tamanho do local e da complexidade da instalação.A instalação do sistema GNV pode melhorar a eficiência energética do posto e reduzir seus custos operacionais a longo prazo.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "A instalação do sistema GNV aumentará o valor do meu posto?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, a instalação do sistema GNV pode aumentar o valor do seu posto, o gás natural veicular é uma opção de combustível cada vez mais popular entre os motoristas, especialmente aqueles que desejam economizar dinheiro e reduzir sua pegada de carbono.Ao oferecer o GNV em seu posto, você pode atrair novos clientes, aumentar sua receita e reduzir seus custos operacionais a longo prazo, o que pode valorizar ainda mais o valor do seu negócio.</p>"
              }
            }
          ]
        }
      }
    },
    "faq": {
      "title": "Comgás Responde: Tudo sobre Gás Encanado e GNV",
      "description": "Resolva suas dúvidas sobre gás encanado, GNV, gás natural e muito mais com a Comgás. Estamos prontos para atendê-lo! Contrate agora!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Página Inicial",
              "item": "https://www.querocomgas.com.br"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Dúvidas Frequentes",
              "item": "https://www.querocomgas.com.br/duvidas-frequentes"
            }
          ]
        },
        "faq": {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [
            {
              "@type": "Question",
              "name": "Quais são as vantagens de usar gás encanado em vez de botijões de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O gás natural encanado proporciona benefícios significativos para residências,comércios e indústrias, incluindo a conveniência de eliminar a necessidade de troca de botijões,garantindo segurança, conforto e um fornecimento contínuo*.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Qual é a disponibilidade de gás encanado na região?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Para saber se há disponibilidade de gás natural encanado em sua região, clique aqui.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Qual é o custo médio de instalação do gás encanado?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O custo médio de instalação do gás natural encanado pode variar de acordo com a região e a complexidade da instalação, clique aqui para obter mais informações.</p"
              }
            },
            {
              "@type": "Question",
              "name": "Existe alguma taxa mensal adicional além do consumo de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<>Na fatura de gás natural encanado da Comgás é cobrado o valor do gás consumido,além das taxas de distribuição e dos impostos correspondentes. Esses valores são detalhados nafatura para que o cliente possa entender melhor o seu consumo e os custos envolvidos. Os reajustes tarifários da Comgás são definidos pela Arsesp (Agência Reguladora de Saneamento e Energia do Estado de São Paulo) e são informados aos clientes por meio de mensagem na conta de gás.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Existem opções de pagamento flexíveis para a instalação e o consumo de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, na Comgás existem opções de pagamento flexíveis para a instalação e oconsumo de gás natural. Para quem mora em condomínio, oferecemos a opção de conta de gás individualizada ou conjunta. É possível programar o pagamento da fatura por débito automático para facilitar o dia a dia, na Comgás, você paga só depois de consumir, a fatura chega de 30 a 45 dias após o consumo.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Há alguma garantia ou seguro incluído na instalação?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, a Comgás oferece garantia na instalação de seus serviços mediante avaliação técnica prévia. Realizamos manutenções periódicas para garantir o bom funcionamento da rede e a segurança dos usuários. Estamos comprometidos em fornecer um serviço de qualidade e confiabilidade aos nossos clientes.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "A ComGás oferece assistência em caso de emergências relacionadas ao gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, a Comgás oferece assistência 24 horas em caso de emergências relacionadas ao gás. Nossa equipe técnica está sempre pronta para atender aos chamados e solucionar qualquer problema com rapidez e eficiência, garantindo a segurança dos nossos clientes.Incentivamos que os usuários informem imediatamente qualquer situação de risco ou suspeita de vazamento de gás para que possamos agir prontamente e evitar acidentes.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "A ComGás realiza inspeções regulares para garantir a segurança do sistema de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<pSim, a ComGás realiza inspeções regulares em sua rede de distribuição de gás natural para garantir a segurança e a qualidade do serviço prestado.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como posso monitorar o consumo de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Para monitorar o consumo de gás encanado, é possível instalar um medidor em sua casa ou empresa.A ComGás marca horários comerciais para realizar a leitura do medidor de gás que você pode consultar a previsão da próxima leitura no App e/ou site da Comgás. É importante lembrar que o medidor é de responsabilidade do cliente e deve ser mantido em boas condições de funcionamento e em local acessível.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como posso saber se há um vazamento de gás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Em caso de suspeita de vazamento de gás, é importante ventilar o ambiente,desligar os aparelhos que utilizam gás, fechar o registro de gás e entrar em contato com a ComGás imediatamente para solucionar o problema.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "É necessário algum tipo de manutenção regular?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Uma das grandes vantagens do gás natural encanado é a baixa necessidade de manutenção em comparação com outros combustíveis fósseis.Isso ocorre porque o gás natural não produz fuligem, o que significa que os equipamentos não precisam de uma manutenção excessiva para remover resíduos.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Que tipo de equipamentos de cozinha são compatíveis com o gás encanado?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>É possível converter a maioria dos equipamentos de cozinha para utilização com gás encanado, como fogões, fornos, churrasqueiras, lareiras e outros equipamentos comerciais e residenciais.Essa conversão é feita por técnicos especializados e garantem que o equipamento funcione perfeitamente com o gás natural encanado, proporcionando maior eficiência e economia no consumo de energia.É importante verificar junto ao fabricante se o equipamento é compatível com o gás natural fornecido pela ComGás antes de realizar a conversão.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Qual é o custo de instalação do sistema de gás natural veicular (GNV)?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O custo médio de instalação do gás natural veicular pode variar de acordo com a região e a complexidade da instalação, entre em contato com a convertedora da sua região para obter mais informações.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Quanto tempo leva para instalar o sistema GNV?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O tempo de instalação de GNV pode variar de acordo com o modelo do veículo e a oficina escolhida. Em média, o processo leva cerca de dois dias úteis.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Onde encontro postos com GNV da Comgás?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A Comgás possui uma rede de postos de combustível com GNV em diversas regiões no estado de São Paulo. É possível encontrar uma lista completa de postos gnv próximos a você aqui.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Existe alguma limitação para o fornecimento de GNV?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Não há especificações para o fornecimento de GNV, desde que o veículo esteja devidamente equipado com o sistema de GNV e atenda às normas de segurança.O gás natural veicular é uma opção viável e econômica para quem busca uma alternativa mais sustentável e econômica em relação aos combustíveis líquidos.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "O sistema GNV requer manutenção regular? Se sim, qual é a frequência e o custo?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Sim, carros movidos a GNV requerem manutenção regular para garantir seu bom funcionamento e segurança.No entanto, a frequência e o custo da manutenção são menores do que a manutenção necessária para veículos movidos a combustíveis líquidos.A utilização do gás natural veicular como combustível reduz a formação de resíduos no motor, o que significa que os componentes do veículo têm uma vida útil mais longa e requerem menos manutenção.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "O que é atendimento por chamada de vídeo?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>É uma forma segura, eficiente e inovadora do especialista de vendas entrar em contato através de uma chamada de vídeo e compreender a necessidade de quem quer ter o gás encanado. </p><p>O usuário reserva um horário e dia para ser atendido por nosso especialista de vendas.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "O atendimento por vídeo chamada da Comgás está disponível para todos os tipos de estabelecimentos?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Não. Atualmente o atendimento por vídeo chamada está disponível apenas para casas habitadas, que não estão em processo de construção, e estabelecimentos comerciais. O agendamento da vídeo chamada é um recurso que não está disponível para todos os endereços.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como é feito o atendimento por vídeo chamada?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Nosso especialista entra em contato, explicando todo o processo de instalação, prazos e condições. Após concordar, o especialista realiza uma visita técnica por meio de uma chamada de vídeo em um ambiente integrado da Comgás.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "O que preciso fazer no momento da ligação por chamada de vídeo?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>1° Estar no local da instalação;</p><p>2° Estar com o celular 100% carregado e com conexão de internet estável;</p><p>3° Dispor de tempo livre para percorrer a casa/comércio que deseja ter o gás encanado.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como remarcar ou cancelar o atendimento por vídeo chamada?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O processo de remarcação ou cancelamento do atendimento para gás encanado é simples. Acesse o link e selecione a opção \"Atendimento com consultor\", fornecendo as informações solicitadas, para seguirmos com a remarcação ou cancelamento.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "O que é ICMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O ICMS, Imposto sobre Circulação de Mercadorias e Prestação de Serviços, é um imposto estadual que incide sobre a circulação de mercadorias em âmbito interestadual, intermunicipal ou exterior. Cada estado tem autonomia para estabelecer suas próprias regras de cobrança desse imposto, seguindo os requisitos mínimos determinados pela Constituição Federal e pelo Código Tributário Nacional.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como é gerado o crédito de ICMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>O crédito de ICMS pode ser gerado devido às diferentes alíquotas praticadas pelos estados e aos incentivos fiscais concedidos pelo governo. Esses incentivos podem incluir redução ou isenção do imposto para produtos enviados para determinadas regiões, inclusive na conta de gás.</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Como a empresa pode utilizar o crédito de ICMS acumulado?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>A empresa pode utilizar o crédito de ICMS acumulado para a compra de equipamentos ou o pagamento de serviços, como contas de água, luz e também na conta de gás encanado. No entanto, essa utilização precisa ser regulamentada e aprovada pelo órgão público competente, geralmente a Secretaria da Fazenda (Sefaz).</p>"
              }
            },
            {
              "@type": "Question",
              "name": "Posso pagar minha fatura de gás encanado com o crédito de ICMS?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "<p>Claro, existe a opção de pagar sua fatura de gás encanado utilizando créditos de ICMS. Esta é uma maneira eficaz de gerenciar seus custos com gás natural encanado.</p>"
              }
            }
          ]
        }
      }
    },
    "swimmingPool": {
      "title": "Piscina Aquecida o ano todo é com a Comgás!",
      "description": "Piscina Aquecida o ano todo é com a Comgás!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.querocomgas.com.br/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Piscinas Aquecidas",
              "item": "https://www.querocomgas.com.br/piscina-aquecida"
            }
          ]
        }
      }
    },
    "blog": {
      "title": "Água quente para apartamentos e casas: Vantagens do aquecimento a gás",
      "description": "Descubra como funciona o aquecimento de água a gás e por que ele é ideal para o seu lar. Saiba mais sobre suas vantagens, eficiência e sustentabilidade!",
      "schemas": {
        "main": {
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "Home",
              "item": "https://www.querocomgas.com.br/"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Blog",
              "item": "https://www.querocomgas.com.br/blog"
            },
            {
              "@type": "ListItem",
              "position": 3,
              "name": "Tenha água quente, conheça a eficiência do aquecimento a gás",
              "item": "https://www.querocomgas.com.br/blog/tenha-agua-quente-conheca-a-eficiencia-do-aquecimento-a-gas"
            }
          ]
        },
        "article": {
          "@context": "https://schema.org",
          "@type": "NewsArticle",
          "headline": "Como funciona o aquecimento a gás e por que ele é ideal para seu ambiente?",
          "image": "https://www.querocomgas.com.br/assets/blog/img/chuveiro-a-gas-natural.png",
          "datePublished": "2024-01-05T08:00:00+08:00",
          "dateModified": "2024-02-05T09:20:00+08:00",
          "author": [
            {
              "@type": "Person",
              "name": "Redação Comgás"
            }
          ]
        }
      }
    },
    "segmentSelection": {
      "title": "Seleção de segmento"
    },
    "addressValidation": {
      "title": "Validação de endereço"
    },
    "schedule": {
      "title": "Agendar"
    },
    "reschedule": {
      "title": "Reagendar"
    },
    "checkSchedule": {
      "title": "Verificar agendamento"
    },
    "consultSchedule": {
      "title": "Consultar agendamento"
    },
    "manageSchedule": {
      "title": "Gerenciar agendamento"
    },
    "scheduleContactConfirm": {
      "title": "@PAGE-TITLE-MANAGE-SCHEDULE-CONTACT-CONFIRM"
    },
    "scheduleFeedback": {
      "title": "Feedback do Agendamento"
    },
    "equipmentSelection": {
      "title": "Seleção de equipamentos"
    },
    "protocol": {
      "title": "Protocolo da solicitação"
    },
    "recoveryProtocol": {
      "title": "Recuperar protocolo"
    },
    "error": {
      "title": "Ocorreu um erro"
    }
  },
  "metatags": {
    "twitter": [
      {
        "name": "twitter:title",
        "content": "Gás encanado? Contrate Comgás agora! | Comgás"
      },
      {
        "name": "twitter:description",
        "content": "Invista em gás encanado e tenha praticidade, economia e segurança em casa. Fuja das trocas de botijões, vazamentos e emissões poluentes. Com o gás encanado, tenha energia sustentável e contínua, além de menor risco de acidentes. Agende já a instalação com nossa equipe experiente e desfrute os benefícios da Comgás!"
      },
      {
        "name": "twitter:image",
        "content": "https://querocomgas.com.br/dist/img/comgas-metatag.png"
      },
      {
        "name": "twitter:card",
        "content": "summary_large_image"
      },
      {
        "name": "twitter:site",
        "content": "@ComgasBR"
      },
      {
        "name": "twitter:creator",
        "content": "@ComgasBR"
      },
      {
        "name": "twitter:url",
        "content": "https://querocomgas.com.br/"
      }
    ],
    "facebook": [
      {
        "property": "og:title",
        "content": "Gás encanado? Contrate Comgás agora! | Comgás"
      },
      {
        "property": "og:description",
        "content": "Invista em gás encanado e tenha praticidade, economia e segurança em casa. Fuja das trocas de botijões, vazamentos e emissões poluentes. Com o gás encanado, tenha energia sustentável e contínua, além de menor risco de acidentes. Agende já a instalação com nossa equipe experiente e desfrute os benefícios da Comgás!"
      },
      {
        "property": "og:type",
        "content": "article"
      },
      {
        "property": "og:url",
        "content": "https://querocomgas.com.br/"
      },
      {
        "property": "og:image",
        "content": "https://querocomgas.com.br/dist/img/comgas-metatag.png"
      }
    ],
    "googlePlus": [
      {
        "itemprop": "name",
        "content": "Gás encanado? Contrate Comgás agora! | Comgás"
      },
      {
        "itemprop": "description",
        "content": "Invista em gás encanado e tenha praticidade, economia e segurança em casa. Fuja das trocas de botijões, vazamentos e emissões poluentes. Com o gás encanado, tenha energia sustentável e contínua, além de menor risco de acidentes. Agende já a instalação com nossa equipe experiente e desfrute os benefícios da Comgás!"
      },
      {
        "itemprop": "image",
        "content": "https://querocomgas.com.br/dist/img/comgas-metatag.png"
      }
    ]
  }
}
